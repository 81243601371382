import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap } from "rxjs/operators";
import { State } from "../../../utils";

interface searchResult {
  data: any[];
  total: number;
}

function matchesStatus(item: any, status: boolean) {
  return status == null ? true : item.status === status;
}

function matches(item: any, searchTerm: string, searchParams: string[]) {
  if (searchTerm == null || searchParams == null) {
    return true;
  }

  for (let i = 0; i < searchParams.length; i++) {
    if (item[searchParams[i]].toLowerCase().includes(searchTerm)) {
      return true;
    }
  }

  return false;
}

const compare = (v1: string, v2: string) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

@Component({
  selector: "app-custom-table",
  templateUrl: "./custom-table.component.html",
  styleUrls: ["./custom-table.component.scss"],
})
export class CustomTableComponent implements OnInit {
  elements: string[] = [];
  public data$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  @Input() dataList: any[];
  @Input() readonly searchParams: string[];
  @Input() enableStatusFilter: boolean = false;
  @Input() enableAction: boolean = true;
  @Input() statusList: any[];
  @Input() enableAdd: boolean = true;
  @Input() enableCustomAction: boolean = false;
  @Input() enableCustomAction2: boolean = false;
  @Input() customAction2Name: string;
  @Input() customActionName: string;
  @Input() enableEdit: boolean = true;
  @Input() paginated: boolean = true;
  @Input() enableDelete: boolean = true;
  @Input() enableSearch: boolean = true;
  @Input() displayHeaders: any[];
  @Output() editItem: EventEmitter<string> = new EventEmitter<string>();
  @Output() customAction: EventEmitter<string> = new EventEmitter<string>();
  @Output() customAction2: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteItem: EventEmitter<string> = new EventEmitter<string>();
  @Output() addItem: EventEmitter<void> = new EventEmitter<void>();
  @Output() rowClick: EventEmitter<any> = new EventEmitter<any>();
  private _State: State;
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _totalData$ = new BehaviorSubject<number>(0);

  constructor(private _router: Router) {}

  get startIndex() {
    return this._State.startIndex;
  }

  get total$() {
    return this._totalData$.asObservable();
  }

  get endIndex() {
    return this._State.endIndex;
  }

  get totalRecords() {
    return this._State.totalRecords;
  }

  get page() {
    return this._State.page;
  }

  set page(page: number) {
    this._set({ page });
  }

  get pageSize() {
    return this._State.pageSize;
  }

  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }

  get searchTerm() {
    return this._State.searchTerm;
  }

  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
    this._search$.next();
  }

  get statusSearchTerm() {
    return this._State.statusSearchTerm;
  }

  set statusSearchTerm(statusSearchTerm: string) {
    this._set({ statusSearchTerm });
    this._search$.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._search$.next();
  }

  ngOnInit(): void {
    this.displayHeaders.forEach((header) => {
      this.elements.push(header.element);
    });

    this._State = {
      page: 1,
      pageSize: this.paginated ? 5 : 40,
      searchTerm: null,
      statusSearchTerm: "",
      sortColumn: "",
      startIndex: 0,
      endIndex: 5,
      totalRecords: 0,
    };
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(100),
        switchMap(() => this._search()),
        delay(100),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this.data$.next(result.data);
        this._totalData$.next(result.total);
      });

    this._search$.next();
  }

  _search(): Observable<searchResult> {
    const { page, searchTerm, statusSearchTerm } = this._State;
    // 1. sort
    let data = this.dataList;

    //filter by  status
    //data = data.filter((item) => matchesStatus(item, statusSearchTerm))
    //search
    data = data.filter((item) => matches(item, searchTerm, this.searchParams));
    const total = data.length;
    // 3. paginate
    this._State.totalRecords = data.length;
    this._State.startIndex = (page - 1) * this.pageSize + 1;
    this._State.endIndex = (page - 1) * this.pageSize + this.pageSize;

    if (this.endIndex > this.totalRecords) {
      this._State.endIndex = this.totalRecords;
    }
    data = data.slice(this._State.startIndex - 1, this._State.endIndex);

    return of({ data, total });
  }

  onEdit(elementId: string) {
    this.editItem.emit(elementId);
  }

  onDelete(elementId: string) {
    this.deleteItem.emit(elementId);
  }

  onCustomAction(elementId: string) {
    this.customAction.emit(elementId);
  }

  onCustomAction2(elementId: string) {
    this.customAction2.emit(elementId);
  }

  onAdd() {
    this.addItem.emit();
  }

  onRowClick(data: any) {
    this.rowClick.emit(data);
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._State, patch);
    this._search$.next();
  }
}
