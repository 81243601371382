import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'environments/environment';
import {Project} from './project';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private _httpClient: HttpClient) {
  }

  getAll(): Observable<any> {

    return this._httpClient
      .get(environment.baseURL + '/project');
  }

  add(project: Project): Observable<any> {
    return this._httpClient
      .post(environment.baseURL + '/project/create', project);
  }

  getById(uuid: string): Observable<any> {
    return this._httpClient
      .get(environment.baseURL + `/project/get/${uuid}`);
  }

  delete(userID: string): Observable<string> {
    return this._httpClient
      .post(environment.baseURL + `/project/delete/${userID}`, '', {responseType: "text"});
  }

  update(project: Project, uuid: string): Observable<any> {
    return this._httpClient
      .put(environment.baseURL + `/project/update/${uuid}`, project);

  }
}
