import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {LoaderService} from 'app/core/services/shared/loader.service';
import {NgProgressComponent} from 'ngx-progressbar';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, AfterViewInit {


  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  sPosition: "left";

  constructor(private loaderService: LoaderService) {
  }

  ngAfterViewInit() {

    this.loaderService.showProgress.subscribe((v) => {
      if (v) {
        this.progressBar.start();
      } else {
        this.progressBar.complete();
      }


    });


  }

  ngOnInit(): void {

  }

}
