<div class="container-boxed col-lg-11 mx-auto">
  <div class="page-title-box mb-0 d-flex justify-content-between">
    <div class="col-sm-4">
      <app-page-title title="Attendance Requests"></app-page-title>
    </div>
    <div class="col-sm-8">
      <ngb-alert
        #selfClosingAlert
        (closed)="message = ''"
        *ngIf="message"
        [dismissible]="false"
        [type]="alertType"
      >
        {{ message }}
      </ngb-alert>
    </div>
  </div>

  <div class="card shadow-lg">
    <div class="card-body">
      <div class="row mb-md-2 mt-3 radius-10">
        <div class="d-flex justify-content-between">
          <!-- Search -->
          <div class="col-sm-3">
            <label>Search</label>
            <input
              [(ngModel)]="searchTerm"
              aria-controls="tickets-table"
              class="form-control form-control"
              placeholder="Search by parameters..."
              name="searchTerm"
              type="text"
            />
          </div>
          <div class="col-sm-3 mb-3">
            <!-- Search -->
            <month-date-picker
              [dataTemp]="monthYearData"
              (monthYear)="updateData($event)"
            ></month-date-picker>
          </div>
        </div>

        <!-- End search -->
      </div>
      <!-- Table -->
      <div class="table-responsive">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>Employee</th>
              <th>Month</th>
              <th>Total Worklog Hrs</th>
              <th style="width: 25%">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of requests$ | async; let i = index">
              <td class="font-weight-semibold">
                {{ data.employee.firstName }} {{ data.employee.middleName }}
              </td>

              <td class="">{{ data.monthName }}, {{ data.year }}</td>
              <td class="">{{ data.logs.totalHrs }}</td>

              <td (click)="$event.stopPropagation()" class="align-middle">
                <div class="btn-group">
                  <button (click)="onDetail(i)" class="btn btn-sm btn-success">
                    <i class="mdi mdi-eye-outline"></i> Work Log
                  </button>

                  <button
                    (click)="onTimesheet(data.employee.uuid)"
                    class="btn btn-sm btn-info"
                  >
                    <i class="mdi mdi-eye-outline"></i> Timesheet
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- End table -->
      <div class="row justify-content-md-between align-items-md-center mt-2">
        <div class="col-sm-12 col-md-5">
          <div
            aria-live="polite"
            class="dataTables_info mb-2"
            id="tickets-table_info"
            role="status"
          >
            Showing {{ startIndex }} to {{ endIndex }} of {{ totalRecords }}
            entries
          </div>
        </div>
        <!-- Pagination -->
        <div class="col-sm-12 col-md-5">
          <div class="text-md-right float-md-end pagination-rounded">
            <ngb-pagination
              [(page)]="page"
              [collectionSize]="total$ | async"
              [pageSize]="pageSize"
            >
            </ngb-pagination>
          </div>
        </div>
        <!-- End Pagination -->
      </div>
    </div>
  </div>

  <div
    *ngIf="showDetails && !showTimesheet"
    class="card bg-light shadow-lg mt-4"
  >
    <div class="card-header bg-light justify-content-between d-flex">
      <strong class="">{{ getEmployee() }}</strong>

      <button (click)="onCloseDetail()" class="btn btn-sm btn-danger-outline">
        <i class="mdi mdi-close"></i>
      </button>
    </div>
    <app-monthly-sheet
      *ngFor="let log of currentLogs"
      (onSuccess)="showSuccess($event)"
      [showCheckBox]="true"
      (onCheckedChange)="onSheetCheckedChange($event, log.week)"
      (onError)="showError($event)"
      [week]="log.week"
      [weekHrs]="log.totalHrsMinute"
      [projects]="projects"
      [workLogs$]="getObservable(log)"
    ></app-monthly-sheet>

    <div class="card-footer">
      <div *ngIf="checkedWeeks.length > 0" class="btn-group col-sm-3">
        <button (click)="onApprove()" class="btn btn-success">Approve</button>
        <button (click)="onDecline()" class="btn btn-danger">Reject</button>
      </div>
    </div>
  </div>
  <div
    *ngIf="showTimesheet && !showDetails"
    class="card bg-light shadow-lg mt-4"
  >
    <div class="card-body">
      <strong>Total Hours: {{ totalHoursMinutes }}</strong>
      <app-custom-table
        [enableAdd]="false"
        [paginated]="false"
        [enableEdit]="false"
        [enableAction]="false"
        [enableDelete]="false"
        [enableCustomAction]="false"
        [enableCustomAction2]="false"
        [dataList]="attendance$ | async"
        [displayHeaders]="attendanceTableHeaders"
        [searchParams]="searchableAttributes"
      >
      </app-custom-table>
    </div>
  </div>

  <ng-template #confirmationModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">{{ modalTitle }}</h5>
      <button
        (click)="modalRef.close('Close click')"
        aria-label="Close"
        class="btn-close"
        type="button"
      ></button>
    </div>

    <div class="modal-body modal-address">
      <div class="form-group">
        <div *ngIf="requestDecline" class="form-group">
          <label for="declineReasonTextarea">Decline Reason:</label>
          <textarea
            placeholder="Enter decline reason..."
            class="form-control border-info"
            id="declineReasonTextarea"
            rows="3"
            [(ngModel)]="declineReason"
            name="declineReason"
          ></textarea>
        </div>

        <div *ngIf="!requestDecline">
          <p>Are you sure you want to approve this leave request?</p>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="modalRef.dismiss()"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="requestDecline && !declineReason"
        (click)="submitConfirmation()"
      >
        Submit
      </button>
    </div>
  </ng-template>
</div>
