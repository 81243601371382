import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LeaveRoutingModule } from './leave-routing.module';
import { MyLeaveComponent } from './my-leave/my-leave.component';

import {
  NgbAccordionModule,
  NgbAlertModule,
  NgbDatepickerModule,
  NgbPaginationModule,
  NgbTooltipModule
} from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from 'app/shared/shared.module';
import { UIModule } from 'app/shared/ui/ui.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { LeaveRequestsComponent } from './leave-requests/leave-requests.component';
import { LeaveRequestTableComponent } from './leave-request-table/leave-request-table.component';
import { NgxMaskModule } from 'ngx-mask';


@NgModule({
  declarations: [
    MyLeaveComponent,
    LeaveRequestsComponent,
    LeaveRequestTableComponent
  ],
  imports: [
    CommonModule,
    NgxMaskModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbAccordionModule,
    NgbAlertModule,
    NgbDatepickerModule,
    LeaveRoutingModule,
    NgbPaginationModule,
    NgbTooltipModule,
    SharedModule,
    UIModule
  ]
})
export class LeaveModule { }
