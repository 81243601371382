<div class="card shadow-lg">
  <div class="card-body">
    <div class="row mb-md-2 mt-3 radius-10">
      <div class="d-flex justify-content-between">
        <div *ngIf="employeeType == 'employee'" class="mb-3">
          <button (click)="addLeaveRequest()" class="btn btn-outline-primary">
            New Request
          </button>
        </div>

        <!-- Search -->
        <div class="col-sm-3">
          <label>Search</label>
          <input
            [(ngModel)]="searchTerm"
            aria-controls="tickets-table"
            class="form-control form-control"
            placeholder="Search by parameters..."
            name="searchTerm"
            type="text"
          />
        </div>
      </div>

      <!-- End search -->
    </div>
    <!-- Table -->
    <div class="table-responsive">
      <table class="table table-sm">
        <thead>
          <tr>
            <th *ngIf="employeeType == 'manager'">Employee</th>
            <th>Type</th>
            <th>From Date</th>
            <th>To Date</th>
            <th>Days</th>
            <th>Balance</th>
            <th>Reason</th>
            <th style="width: 15%">Status</th>
            <th style="width: 15%">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of leaves$ | async; let i = index">
            <td *ngIf="employeeType == 'manager'" class="font-weight-semibold">
              {{ data.employee.firstName }} {{ data.employee.middleName }}
            </td>
            <td class="font-weight-semibold">{{ data.leaveType }}</td>
            <td class="">{{ data.fromDate }}</td>
            <td class="">{{ data.toDate }}</td>
            <td class="font-weight-semibold">{{ data.days }}</td>
            <td class="font-weight-semibold">{{ data.remainingBalance }}</td>
            <td class="">{{ data.remark }}</td>
            <td
              [ngClass]="{
                'bg-light': data.statusDescription === 'PENDING',
                'bg-info bg-soft':
                  data.statusDescription === 'APPROVED_MANAGER',
                'bg-success bg-soft': data.statusDescription === 'HR_APPROVED',
                'bg-danger bg-soft': data.statusDescription === 'REJECTED'
              }"
            >
              {{ data.statusDescription }}
            </td>
            <td
              *ngIf="
                employeeType == 'manager' &&
                data.statusDescription === 'PENDING'
              "
              (click)="$event.stopPropagation()"
              class="align-middle"
            >
              <div class="btn-group">
                <button (click)="approve(data)" class="btn btn-sm btn-success">
                  Approve
                </button>
                <button (click)="decline(data)" class="btn btn-sm btn-danger">
                  Reject
                </button>
              </div>
            </td>
            <td
              *ngIf="employeeType == 'employee'"
              (click)="$event.stopPropagation()"
              class="align-middle"
            >
              <div class="btn-group">
                <button
                  (click)="updateLeaveRequest(data)"
                  *ngIf="
                    data.statusDescription === 'PENDING' ||
                    data.statusDescription === 'REJECTED'
                  "
                  class="btn btn-sm btn-success"
                >
                  Update
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- End table -->
    <div class="row justify-content-md-between align-items-md-center mt-2">
      <div class="col-sm-12 col-md-5">
        <div
          aria-live="polite"
          class="dataTables_info mb-2"
          id="tickets-table_info"
          role="status"
        >
          Showing {{ startIndex }} to {{ endIndex }} of {{ totalRecords }}
          entries
        </div>
      </div>
      <!-- Pagination -->
      <div class="col-sm-12 col-md-5">
        <div class="text-md-right float-md-end pagination-rounded">
          <ngb-pagination
            [(page)]="page"
            [collectionSize]="total$ | async"
            [pageSize]="pageSize"
          >
          </ngb-pagination>
        </div>
      </div>
      <!-- End Pagination -->
    </div>
  </div>
</div>
