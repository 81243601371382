import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { Leave, LeaveType } from '../services/leave/leave';
import { LeaveService } from '../services/leave/leave.service';
import { AuthService } from '../services/auth/auth.service';
import { LeaveStatistics } from '../services/leave/LeaveStat';

@Injectable({
  providedIn: 'root'
})
export class EmployeeLeaveResolver implements Resolve<[LeaveType[], Leave[], LeaveStatistics]> {

  constructor(private leaveService: LeaveService, private authService: AuthService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<[LeaveType[], Leave[], LeaveStatistics]> {

    const emplUuid = this.authService.currentUser().employeeUuid;
    const leaveTypes$ = this.leaveService.getLeaveTypes();
    const leaves = this.leaveService.getLeaveRequests('employee', emplUuid);
    const leaveStat$ = this.leaveService.getLeaveStatistics(emplUuid);

    return forkJoin([leaveTypes$, leaves, leaveStat$]);
  }
}
