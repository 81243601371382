import { MenuItem } from './menu.model';


const HR_MANAGER_NAV_ITEMS: MenuItem[] = [

  {

    id: 1,
    label: 'Dashboards',
    icon: 'mdi mdi-view-dashboard-outline',
    link: '/dashboard'
  },
  {
    id: 8,
    label: 'MODULES',
    isTitle: true
  },
  {
    id: 9,
    label: 'Employee',
    icon: 'bx bx-user',
    link: '/employee',
  },
  {
    id: 10,
    label: 'Performance',
    icon: 'mdi mdi-scale-balance',
    subItems: [

      {
        id: 12,
        label: 'Criteria Config',
        link: '/performance/criteria',
        parentId: 10
      },
      {
        id: 12,
        label: 'Evaluation Config',
        icon: 'mdi mdi-scale-balance',
        link: '/performance/evaluation',
        parentId: 10
      },
      {
        id: 11,
        label: 'Approval Requests',
        icon: 'bx bx-user-check',
        link: '/performance/approvals',
      },
      {
        id: 12,
        label: 'My Evaluations',
        icon: 'mdi mdi-scale-balance',
        link: '/performance/employee'
      },
      {
        id: 13,
        label: 'Evaluations',
        icon: 'mdi mdi-scale',
        link: '/performance/supervisee'
      },
      {
        id: 18,
        label: 'Monthly Plan',
        icon: 'mdi mdi-format-list-checks',
        link: '/performance/monthly-plan'
      },
      {
        id: 19,
        label: 'Plan Request',
        icon: 'mdi mdi-format-list-checks',
        link: '/performance/monthly-plan-requests'
      },
    ]
  },

  {
    id: 12,
    label: 'Vacancy',
    icon: 'bx bx-briefcase-alt-2',
    link: '/vacancies',
  },


  {
    id: 146,
    label: 'Leave',
    icon: 'mdi mdi-calendar-range-outline',
    subItems: [
      {
        id: 14,
        label: 'Leaves',
        link: '/leaves/my-leave'
      },
      {
        id: 15,
        label: 'Requests',
        icon: 'mdi mdi-calendar-check-outline',
        link: '/leaves/requests'
      },



    ]
  },

  {
    id: 148,
    label: 'Attendance',
    icon: 'mdi mdi-timer-outline',
    subItems: [
      {
        id: 16,
        label: 'Time Sheet',
        icon: '  mdi mdi-timer-outline',
        link: '/attendance/timesheet'
      },

      {
        id: 17,
        label: 'Requests',
        icon: '  mdi mdi-camera-timer-outline',
        link: '/attendance/timesheet-request'
      },

    ]
  },




  {
    id: 26,
    label: 'Users',
    icon: 'dripicons-user-group',
    link: "/users"
  },
  {
    id: 24,
    label: 'Settings',
    isTitle: true
  },
  {
    id: 28,
    label: 'System Settings',
    icon: 'dripicons-toggles',
    link: ""
  },
];

const EMPLOYEE_NAV_ITEMS: MenuItem[] = [
  {

    id: 1,
    label: 'Dashboards',
    icon: 'mdi mdi-view-dashboard-outline',
    link: '/dashboard/employee'
  },
  {
    id: 8,
    label: 'MODULES',
    isTitle: true
  },

  {
    id: 145,
    label: "Performance",
    icon: "mdi mdi-scale-balance",
    subItems: [{
      id: 12,
      label: 'My Evaluations',
      icon: 'mdi mdi-scale-balance',
      link: '/performance/employee'
    },
    {
      id: 13,
      label: 'Evaluations',
      icon: 'mdi mdi-scale',
      link: '/performance/supervisee'
    },
    {
      id: 18,
      label: 'Monthly Plan',
      icon: 'mdi mdi-format-list-checks',
      link: '/performance/monthly-plan'
    },
    {
      id: 19,
      label: 'Plan Request',
      icon: 'mdi mdi-format-list-checks',
      link: '/performance/monthly-plan-requests'
    },

    ]
  },
  {
    id: 146,
    label: 'Leave',
    icon: 'mdi mdi-calendar-range-outline',
    subItems: [
      {
        id: 14,
        label: 'Leaves',
        link: '/leaves/my-leave'
      },
      {
        id: 15,
        label: 'Requests',
        icon: 'mdi mdi-calendar-check-outline',
        link: '/leaves/requests'
      },
    ]
  },

  {
    id: 148,
    label: 'Attendance',
    icon: 'mdi mdi-timer-outline',
    subItems: [
      {
        id: 16,
        label: 'Time Sheet',
        icon: '  mdi mdi-timer-outline',
        link: '/attendance/timesheet'
      },

      {
        id: 17,
        label: 'Requests',
        icon: '  mdi mdi-camera-timer-outline',
        link: '/attendance/timesheet-request'
      },

    ]
  },


  {
    id: 28,
    label: 'System Settings',
    icon: 'dripicons-toggles',
    link: "/settings"
  },
];

export { HR_MANAGER_NAV_ITEMS, EMPLOYEE_NAV_ITEMS }

