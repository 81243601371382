import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyLeaveComponent } from './my-leave/my-leave.component';
import { LeaveRequestsComponent } from './leave-requests/leave-requests.component';
import { AttendanceLogComponent } from '../attendance/attendance-log/attendance-log.component';
import { EmployeeLeaveResolver } from 'app/core/resolvers/employee-leave.resolver';
import { ManagerLeaveResolver } from 'app/core/resolvers/manager-leave.resolver';

const routes: Routes = [
  {
    path: 'my-leave',
    resolve: {
      apiData: EmployeeLeaveResolver
    },
    component: MyLeaveComponent
  },

  {
    path: 'requests',
    resolve: {
      apiData: ManagerLeaveResolver
    },
    component: LeaveRequestsComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeaveRoutingModule { }
