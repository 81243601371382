import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  AttendanceLog,
  WorkLogRequest,
} from "app/modules/attendance/attendance";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { Attendance } from "./attendance";

@Injectable({
  providedIn: "root",
})
export class TimesheetService {
  constructor(private _httpClient: HttpClient) {}

  logStart(uuid: string): Observable<any> {
    return this._httpClient.post(
      `${environment.baseURL}/attendance/${uuid}/start`,
      {}
    );
  }

  logEnd(uuid: string): Observable<any> {
    return this._httpClient.post(
      `${environment.baseURL}/attendance/${uuid}/end`,
      {}
    );
  }

  getAttendanceList(
    empUuid: string,
    month: number,
    year: number
  ): Observable<Attendance[]> {
    return this._httpClient.get<Attendance[]>(
      `${environment.baseURL}/attendance/${empUuid}/list?year=${year}&month=${month}`
    );
  }

  getWeeklyLog(
    month: number,
    year: number,
    week: number,
    empUuid: string
  ): Observable<AttendanceLog> {
    return this._httpClient.get<AttendanceLog>(
      `${environment.baseURL}/attendance/${empUuid}/worklog?year=${year}&month=${month}&week=${week}`
    );
  }

  saveWorkLog(empUuid: string, data: any) {
    return this._httpClient.post(
      `${environment.baseURL}/attendance/${empUuid}/worklog`,
      data
    );
  }

  submittWorkLog(empUuid: string, data: any) {
    return this._httpClient.put(
      `${environment.baseURL}/attendance/${empUuid}/worklog/submit`,
      data
    );
  }

  getWorkLogRequest(
    month: number,
    year: number,
    empUuid: string
  ): Observable<WorkLogRequest[]> {
    return this._httpClient.get<WorkLogRequest[]>(
      `${environment.baseURL}/attendance/supervisor/${empUuid}/worklog?year=${year}&month=${month}`
    );
  }

  getEmployeeCompletedTimesheet(
    month: number,
    year: number,
    empUuid: string,
    status: string
  ): Observable<WorkLogRequest[]> {
    return this._httpClient.get<WorkLogRequest[]>(
      `${environment.baseURL}/attendance/${empUuid}/worklog?year=${year}&month=${month} &status=${status}`
    );
  }

  approveWorkLog(empUuid: string, data: any) {
    return this._httpClient.put(
      `${environment.baseURL}/attendance/${empUuid}/worklog/approve`,
      data
    );
  }
}
