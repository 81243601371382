<div class="container-boxed col-lg-11 mx-auto">

  <div class="page-title-box d-flex  justify-content-between">
    <div class="col-sm-4">
      <app-page-title title="USERS LIST">
      </app-page-title>
    </div>
    <div class="col-sm-8">
      <ngb-alert #selfClosingAlert (closed)="message = ''" *ngIf="message" [dismissible]="false" [type]="alertType">{{
        message
        }}
      </ngb-alert>

    </div>
  </div>

  <div class="card">

    <div class="card-body">
      <div class="mb-3">
        <button (click)="onAddUser()" class="btn btn-outline-primary">Add user</button>
      </div>
      <user-table
        (accountAction)="onAccountEvent($event)"
        (editUser)="onEditUser($event)"
        [disableAllowed]="disableAllowed"
        [userList]="users$ | async"></user-table>
    </div>
  </div>

</div>

<ng-template #userModal let-modal>

  <div class="modal-header">
    <h5 class="modal-title mt-0">{{modalTitle}}</h5>
    <button (click)="modalRef.close('Close click')" aria-label="Close" class="btn-close" type="button">
    </button>
  </div>

  <div class="modal-body modal-address">

    <!--User form -->
    <app-user-form
      (onSubmit)="onSubmitEvent($event)"
      [isUpdate]="isUpdate"
      [user]="userToBeUpdated"></app-user-form>

  </div>


</ng-template>


<ng-template #confirmationModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{confirmationHeader}}</h5>
    <button (click)="modal.dismiss('Cross click')" aria-hidden="true" class="btn-close" type="button"></button>
  </div>
  <div class="modal-body">
    <p>{{confirmationMessage}}</p>

  </div>
  <div class="modal-footer">
    <button (click)="onConfirmAction()" class="btn btn-primary" type="button">Yes</button>
    <button (click)="modal.dismiss('Cross click')" class="btn btn-secondary" data-dismiss="modal"
            type="button">Cancel
    </button>
  </div>
</ng-template>

