import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbAlert, NgbCalendar, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/core/services/auth/auth.service";
import { LoaderService } from "app/core/services/shared/loader.service";
import { TimesheetService } from "app/core/services/timesheet/timesheet.service";
import * as moment from "moment";
import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { AttendanceLog, WorkLog } from "../attendance";

@Component({
  selector: "app-calendar",
  templateUrl: "./attendance-log.component.html",
  styleUrls: ["./attendance-log.component.scss"],
})
export class AttendanceLogComponent implements OnInit {
  // bread crumb items
  breadCrumbItems: Array<{}>;

  @ViewChild("confirmationModal") confirmationModal: TemplateRef<any>;
  @ViewChild("selfClosingAlert", { static: false }) selfClosingAlert: NgbAlert;
  public modalRef: any;
  fromDate: any;
  year: number;
  month: number;
  toDate: any;
  weekNumber: number;
  weekHrs: number;
  totalCheckedIntime: number;
  attenndaceLog: AttendanceLog;
  _message = new Subject<string>();
  message = "";
  submit: boolean = false;
  week: number = null;
  alertType = "";
  projects: any;
  logType: "start" | "end";
  showSummary: boolean = false;
  log$: BehaviorSubject<WorkLog> = new BehaviorSubject(null);

  constructor(
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private loaderService: LoaderService,
    private _authservice: AuthService,
    private activatedRoute: ActivatedRoute,
    private _timeSheetService: TimesheetService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    //fetch projects
    this.activatedRoute.data.subscribe((data: any) => {
      this.projects = data.data;
    });

    //get current week logs
    const now = moment();
    this.year = Number(now.format("YYYY"));
    this.month = Number(now.format("MM"));

    this.setFromDate(now.clone().startOf("isoWeek").format("DD-MM-YYYY"));
    this.setToDate(now.clone().endOf("isoWeek").format("DD-MM-YYYY"));
    this.weekNumber = now.isoWeek();

    this.getWeekLog(this.weekNumber, this.year, this.month);

    //config alert
    this._message.pipe(debounceTime(3000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }
  showError(message: string) {
    //error notification
    this.alertType = "danger";
    this._message.next(this.message);
    this.message = message;
  }

  showSuccess(message: string) {
    this.alertType = "success";
    this._message.next(this.message);
    this.message = message;
  }

  onFromDateChange(date: any) {
    if (date) {
      const momentDate = moment([date.year, date.month - 1, date.day]);
      this.year = date.year;
      this.month = date.month;
      const weekStart = momentDate.clone().startOf("isoWeek");
      const weekEnd = momentDate.clone().endOf("isoWeek");
      const weekNumber = momentDate.isoWeek();
      this.setFromDate(weekStart.format("DD-MM-YYYY"));
      this.setToDate(weekEnd.format("DD-MM-YYYY"));
      this.weekNumber = weekNumber;
      this.getWeekLog(this.weekNumber, date.year, date.month);
    }
  }

  getWeekLog(week: number, year: number, month: number) {
    this.loaderService.showProgress.next(true);
    this._timeSheetService
      .getWeeklyLog(
        month,
        year,
        week,
        this._authservice.currentUser().employeeUuid
      )
      .subscribe({
        next: (resp) => {
          this.showSummary = true;
          this.loaderService.showProgress.next(false);
          this.attenndaceLog = resp;
          this.log$.next(this.attenndaceLog.workLogs[0]);
          this.weekHrs = this.attenndaceLog.workLogs[0].totalHrsMinute;
          this.totalCheckedIntime =
            this.attenndaceLog.workLogs[0].totalCheckInTimeMinute;
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  setToDate(dateStr: string): void {
    const [day, month, year] = dateStr.split("-").map(Number);
    this.toDate = { year, month, day };
  }

  setFromDate(dateStr: string): void {
    const [day, month, year] = dateStr.split("-").map(Number);
    this.fromDate = { year, month, day };
  }

  submitConfirmation() {
    this.loaderService.showProgress.next(true);
    if (this.logType == "start") {
      this._timeSheetService
        .logStart(this._authservice.currentUser().employeeUuid)
        .subscribe({
          next: (resp) => {
            this.modalService.dismissAll();
            this.loaderService.showProgress.next(false);
            //success notification
            this.alertType = "success";
            this._message.next(this.message);
            this.message = "Log added successfully!";
          },
          error: (err) => {
            console.log(err);
            this.loaderService.showProgress.next(false);
            this.modalService.dismissAll();
            //error notification
            this.alertType = "danger";
            this._message.next(this.message);
            this.message =
              "Error sending  your request! please try again later";
          },
        });
    } else {
      this._timeSheetService
        .logEnd(this._authservice.currentUser().employeeUuid)
        .subscribe({
          next: (resp) => {
            console.log(resp);
            this.modalService.dismissAll();
            this.loaderService.showProgress.next(false);
            //success notification
            this.alertType = "success";
            this._message.next(this.message);
            this.message = "Log added successfully!";
          },
          error: (err) => {
            console.log(err);
            this.loaderService.showProgress.next(false);
            this.modalService.dismissAll();
            //error notification
            this.alertType = "danger";
            this._message.next(this.message);
            this.message =
              "Error sending  your request! please try again later";
          },
        });
    }
  }

  onLog(type: "start" | "end") {
    this.logType = type;
    this.modalRef = this.modalService.open(this.confirmationModal, {
      scrollable: false,
      size: "md",
      centered: false,
    });
  }
}
