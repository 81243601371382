import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthService} from '../services/auth/auth.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private _authService: AuthService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this._authService.isLoggedIn()
      .pipe(
        map(loggedIn => {
          if (loggedIn) {
            return true;
          } else {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/account/login'], {queryParams: {returnUrl: state.url}});
            return false;
          }
        }));

  }

}
