import {Component, OnInit, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Choice, Question, Vacancy} from "../vacancy.model";
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../../../core/services/shared/loader.service";
import {VacancyService} from "../../../core/services/vacancy/vacancy.service";
import {NgbAlert} from "@ng-bootstrap/ng-bootstrap";
import {debounceTime} from "rxjs/operators";
import {Country} from "country-state-city";

@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss']
})
export class ApplicationFormComponent implements OnInit {
  _message = new Subject<string>();
  message = "";
  alertType = "";
  vacancy: Vacancy;
  countryCodes;

  steps = [
    {label: 'Personal Information'},
    {label: 'Education'},
    {label: 'Professional Skills'},
    {label: 'Survey'}
  ];
  currentStep = 1;
  applicationForm: FormGroup;

  questions: Question[];
  finished: boolean = false;
  cvFile: File;
  public applicantFullName: string;
  public applicantEmail: string
  @ViewChild("selfClosingAlert", {static: false}) selfClosingAlert: NgbAlert;
  educationLevels = [
    "High School Diploma",
    "Professional Certification",
    "Technical Vocational Certificate",
    "Associate's Degree",
    "Certificate",
    "Bachelor of Arts (BA)",
    "Bachelor of Engineering (BEng)",
    "Bachelor of Science (BSc)",
    "Doctor of Dental Medicine (DMD)",
    "Doctor of Medicine (MD)",
    "Doctor of Veterinary Medicine (DVM)",
    "Doctor of Pharmacy (PharmD)",
    "Postgraduate Diploma",
    "Master of Arts (MA)",
    "Master of Business Administration (MBA)",
    "Master of Science (MSc)",
    "Master of Public Administration (MPA)",
    "Master of Public Health (MPH)",
    "MD Specialist",
    "Doctor of Philosophy (PhD)"
  ];
  categories = [
    {
      name: "Agriculture and Natural Resources",
      subcategories: [
        "Agribusiness",
        "Agriculture Science",
        "Animal Science",
        "Forestry",
        "Horticulture",
        "Other Agriculture and Natural Resources"
      ]
    },
    {
      name: "Arts and Design",
      subcategories: [
        "Dance",
        "Fashion Design",
        "Film and Media Studies",
        "Fine Arts",
        "Graphic Design",
        "Interior Design",
        "Music",
        "Theater",
        "Other Arts and Design"
      ]
    },
    {
      name: "Business and Economic",
      subcategories: [
        "Accounting",
        "Business Administration",
        "Economics",
        "Entrepreneurship",
        "Finance",
        "International Business",
        "Management",
        "Marketing",
        "Other Business and Economic"
      ]
    },
    {
      name: "Communication and Media",
      subcategories: [
        "Advertising",
        "Communication Studies",
        "Film Studies",
        "Journalism",
        "Media Production",
        "Public Relations",
        "Other Communication and Media"
      ]
    },
    {
      name: "Education",
      subcategories: [
        "Curriculum and Instruction",
        "Early Childhood Education",
        "Educational Leadership",
        "Elementary Education",
        "Higher Education",
        "Secondary Education",
        "Special Education",
        "Other Education"
      ]
    },
    {
      name: "Engineering and Technology",
      subcategories: [
        "Aerospace Engineering",
        "Biomedical Engineering",
        "Chemical Engineering",
        "Civil Engineering",
        "Computer Engineering",
        "Electrical Engineering",
        "Mechanical Engineering",
        "Software Engineering",
        "Other Engineering and Technology"
      ]
    },
    {
      name: "Environmental Studies",
      subcategories: [
        "Conservation Biology",
        "Ecology",
        "Environmental Policy",
        "Environmental Science",
        "Sustainability Studies",
        "Other Environmental Studies"
      ]
    },
    {
      name: "Health and Medicine",
      subcategories: [
        "Anesthesia",
        "Dentistry",
        "Medical Laboratory",
        "Medical Radiography",
        "Medicine",
        "Nursing",
        "Nutrition",
        "Occupational Therapy",
        "Pharmacy",
        "Physical Therapy",
        "Psychiatry",
        "Public Health",
        "Other Health and Medicine"
      ]
    },
    {
      name: "Humanities",
      subcategories: [
        "Archaeology",
        "Art History",
        "Classics",
        "Cultural Studies",
        "History",
        "Literature",
        "Philosophy",
        "Religious Studies",
        "Other Humanities"
      ]
    },
    {
      name: "Languages and Linguistics",
      subcategories: [
        "Amharic",
        "Chinese",
        "English",
        "French",
        "Linguistics",
        "Translation and Interpretation",
        "Other Languages and Linguistics"
      ]
    },
    {
      name: "Law and Criminal Justice",
      subcategories: [
        "Criminal Justice",
        "Criminology",
        "Forensic Science",
        "Law",
        "Legal Studies",
        "Other Law and Criminal Justice"
      ]
    },
    {
      name: "Physical and Earth Sciences",
      subcategories: [
        "Astronomy",
        "Geology",
        "Meteorology",
        "Oceanography",
        "Physics",
        "Other Physical and Earth Sciences"
      ]
    },
    {
      name: "Science and Mathematics",
      subcategories: [
        "Astronomy",
        "Biology",
        "Chemistry",
        "Computer Science",
        "Environmental Science",
        "Geology",
        "Mathematics",
        "Physics",
        "Other Science and Mathematics"
      ]
    },
    {
      name: "Social Sciences",
      subcategories: [
        "Anthropology",
        "Economics",
        "Geography",
        "International Relations",
        "Linguistics",
        "Political Science",
        "Psychology",
        "Sociology",
        "Other Social Sciences"
      ]
    },
    {
      name: "Sports and Recreation",
      subcategories: [
        "Exercise Physiology",
        "Kinesiology",
        "Recreation Management",
        "Sports Management",
        "Sports Science",
        "Other Sports and Recreation"
      ]
    }
  ];

  constructor(private formBuilder: FormBuilder,
              private _loaderService: LoaderService,
              private _vacancyService: VacancyService,
              private router: Router,
              private route: ActivatedRoute,) {
    this.countryCodes = Country.getAllCountries()
      .filter((country) => country.phonecode !== undefined)
      .map((country) => ({
        name: country.name,
        code: country.phonecode,
      }))

    this.applicationForm = this.formBuilder.group({
      step1: this.formBuilder.group({
        firstName: [null, Validators.required],
        middleName: [null, Validators.required],
        lastName: [null, Validators.required],
        cv: ['', Validators.required],
        gender: ['', Validators.required],
        email: [null, [Validators.required, Validators.email]],
        phoneNumber: [null],
        countryCode: [this.countryCodes.find(code => code.code === '251')],
        addressId: [null]
      }),
      step2: this.formBuilder.group({
        educationType: [null, Validators.required],
        educationLevel: ['', Validators.required]
      }),
      step3: this.formBuilder.group({
        totalExperience: [null, Validators.required],
        skills: [null]
      }),
      step4: this.formBuilder.group({
        questions: this.formBuilder.array([])
      })
    });
  }

  get questionArray() {
    return this.applicationForm.get('step4').get('questions') as FormArray;
  }

  previousStep() {
    this.currentStep--;
  }

  nextStep() {
    const currentStepForm = this.applicationForm.get(`step${this.currentStep}`);
    currentStepForm.markAllAsTouched();
    if (currentStepForm.valid) {
      this.currentStep++;
    }
  }

  submitForm() {
    // handle form submission
    const formData = new FormData();
    const formGroupObj = Object.assign({},
      this.applicationForm.get('step1').value,
      this.applicationForm.get('step2').value,
      this.applicationForm.get('step3').value,
      this.applicationForm.get('step4').value);

    formData.append('cv', this.cvFile);
    const data = {
      firstName: formGroupObj.firstName,
      middleName: formGroupObj.middleName,
      lastName: formGroupObj.lastName,
      gender: formGroupObj.gender,
      email: formGroupObj.email,
      phoneNumber: formGroupObj.phoneNumber,
      educationType: formGroupObj.educationType,
      educationLevel: formGroupObj.educationLevel,
      totalExperience: formGroupObj.totalExperience,
      skills: formGroupObj.skills,
      questions: formGroupObj.questions
    };
    formData.append('data', JSON.stringify(data));
    this._loaderService.showProgress.next(true);
    this._vacancyService.submitApplication(formData, this.vacancy.uuid)
      .subscribe({
        next: (resp: any) => {
          this.applicantFullName = resp.fullName;
          this.applicantEmail = resp.email;
          this.finished = true;
          this._loaderService.showProgress.next(false);
        },
        error: (error) => {
          this.alertType = "danger";
          this._message.next(this.message);
          this.message = error.error.apierror.message;
          this._loaderService.showProgress.next(false);
        }
      })
  }

  ngOnInit(): void {


    this.vacancy = this.route.snapshot.data.vacancy;

    if (this.vacancy.status !== 'PUBLISHED') {
      this.router.navigateByUrl('/404')
    }
    //config alert
    this._message.pipe(debounceTime(3000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });

    this.questions = this.vacancy.questions;
    // Define the form array for the questions
    const questionsArray = this.applicationForm.get('step4').get('questions') as FormArray;

    // Loop through the questions data and add each question as a form group
    for (let i = 0; i < this.questions.length; i++) {
      const question = this.questions[i];
      let questionGroup: FormGroup;

      if (question.questionType === 'SHORT_ANSWER') {
        questionGroup = this.formBuilder.group({
          questionUuid: question.uuid,
          answer: ''
        });
      } else {
        const choicesArray = this.formBuilder.array(
          question.choices.map(choice => this.formBuilder.group({
            uuid: choice.uuid,
            selected: false
          }))
        );

        questionGroup = this.formBuilder.group({
          questionUuid: question.uuid,
          answer: '',
          choiceUuids: choicesArray
        });

      }
      questionsArray.push(questionGroup);
    }
  }

  getQuestionByUuid(uuid: string): Question | null {
    return this.questions.find(question => question.uuid === uuid) || null;
  }

  getChoiceByUuid(choices: Choice[], uuid: string): Choice | undefined {
    return choices.find(choice => choice.uuid === uuid);
  }

  clearRadioGroup(formArray: FormArray, selectedIndex: number) {
    formArray.controls.forEach((control, index) => {

      if (index !== selectedIndex) {
        control.get('selected').setValue(false);
      }
    });
  }

  onFileSelected($event: any) {
    this.cvFile = $event.target.files[0];
  }
}
