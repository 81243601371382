import { DatePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbAlert, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/core/services/auth/auth.service";
import { LoaderService } from "app/core/services/shared/loader.service";
import { Idata } from "app/core/services/shared/month-date-picker/month-date-picker.component";
import { TimesheetService } from "app/core/services/timesheet/timesheet.service";
import { State } from "app/shared/utils";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap } from "rxjs/operators";
import {
  Attendance,
  AttendanceApproval,
  AttendanceStatus,
  WorkLog,
  WorkLogRequest,
} from "../attendance";

interface searchResult {
  data: any[];
  total: number;
}
export function matches(request: any, term: string) {
  return request.employee.firstName.toLowerCase().includes(term);
}

@Component({
  selector: "app-attendance-request",
  templateUrl: "./attendance-request.component.html",
  styleUrls: ["./attendance-request.component.scss"],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttendanceRequestComponent implements OnInit {
  _message = new Subject<string>();
  message = "";
  totalHoursMinutes: string;
  attendance$: BehaviorSubject<Attendance[]> = new BehaviorSubject<
    Attendance[]
  >([]);
  attendanceTableHeaders = [
    {
      name: "Date",
      element: "attendanceDate",
    },
    {
      name: "Start Time",
      element: "startTime",
    },
    {
      name: "End Time",
      element: "endTime",
    },
    {
      name: "Work Hours",
      element: "totalHrsMinute",
    },
    {
      name: "Status",
      element: "status",
    },
    {
      name: "IP address",
      element: "ip",
    },
    {
      name: "Description",
      element: "description",
    },
  ];
  searchableAttributes = ["leaveType"];
  submit: boolean = false;
  showDetails: boolean = false;
  showTimesheet: boolean = false;
  private _State: State;
  public requests$ = new BehaviorSubject<WorkLogRequest[]>([]);
  private _search$ = new Subject<void>();
  private _totalRequests$ = new BehaviorSubject<number>(0);
  private _loading$ = new BehaviorSubject<boolean>(true);
  public modalRef: any;
  requestDecline: boolean = false;
  declineReason: string;
  public attendanceList: Attendance[] = [];
  year: number;
  month: number;
  currentLogs: WorkLog[];
  monthYearData: Idata;
  months: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  projects: any;
  currentIndex: number;
  logs$: BehaviorSubject<WorkLog[]> = new BehaviorSubject(null);
  weekNumber: number;
  alertType = "";
  saving = false;
  modalTitle: string;
  checkedWeeks: number[] = [];
  @ViewChild("confirmationModal") confirmationModal: ElementRef;
  @ViewChild("selfClosingAlert", { static: false }) selfClosingAlert: NgbAlert;
  requests: WorkLogRequest[];

  constructor(
    private _modalService: NgbModal,
    private datePiepe: DatePipe,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private timeSheetService: TimesheetService,
    private _changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.setCurrentMonthYear();
    //fetch projects
    this.activatedRoute.data.subscribe((data: any) => {
      this.projects = data.data;
    });

    this.refreshData();
    //this.fetchAttendance();

    this._State = {
      page: 1,
      pageSize: 5,
      searchTerm: "",
      statusSearchTerm: "",
      sortColumn: "",
      startIndex: 0,
      endIndex: 5,
      totalRecords: 0,
    };

    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(100),
        switchMap(() => this._search()),
        delay(100),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this.requests$.next(result.data);
        this._totalRequests$.next(result.total);
      });
  }

  formatTime(timeString: string): string {
    // Append a dummy date to the time string
    const fullDateTime = `1970-01-01T${timeString}`;
    const date = new Date(fullDateTime);
    return this.datePiepe.transform(date, "HH:mm:ss");
  }
  updateData(data: Idata) {
    this.monthYearData = data;
    this.refreshData();
    //this.fetchAttendance();
  }

  refreshData() {
    this.loaderService.showProgress.next(true);
    this.timeSheetService
      .getWorkLogRequest(
        this.monthYearData.month,
        this.monthYearData.year,
        this.authService.currentUser().employeeUuid
      )
      .subscribe((resp) => {
        this.requests = resp;
        this.requests$.next(this.requests);

        this._search$.next();
        this.loaderService.showProgress.next(false);
      });
  }
  fetchAttendance(uuid: string) {
    this.loaderService.showProgress.next(true);
    this.timeSheetService
      .getAttendanceList(
        uuid,
        this.monthYearData.month,
        this.monthYearData.year
      )
      .subscribe({
        next: (resp: any) => {
          this.attendanceList = resp;
          this.totalHoursMinutes = this.calculateTotalHoursMinutes(
            this.attendanceList
          );
          this.attendance$.next(
            this.attendanceList.map((attendance) => {
              return {
                ...attendance,
                startTime: attendance.startTime
                  ? this.formatTime(attendance.startTime)
                  : attendance.startTime,
                endTime: attendance.endTime
                  ? this.formatTime(attendance.endTime)
                  : attendance.endTime,
                attendanceDate: this.datePiepe.transform(
                  attendance.attendanceDate,
                  "dd-MM-yyyy"
                ),
              };
            })
          );

          this.loaderService.showProgress.next(false);
        },
      });
  }
  public calculateTotalHoursMinutes(attendanceList: Attendance[]): string {
    let totalMinutes = 0;

    for (const attendance of attendanceList) {
      const [hours, minutes] = attendance.totalHrsMinute.split(":").map(Number);
      totalMinutes += hours * 60 + minutes;
    }

    const totalHours = Math.floor(totalMinutes / 60);
    const totalMinutesRemaining = totalMinutes % 60;

    return `${totalHours}:${totalMinutesRemaining.toString().padStart(2, "0")}`;
  }
  setCurrentMonthYear() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 since getMonth() returns a zero-based index
    const currentYear = currentDate.getFullYear();

    this.monthYearData = {
      month: currentMonth,
      year: currentYear,
    };
  }

  onApprove() {
    this.requestDecline = false;
    this.modalTitle = "Approve Attendance";
    this.modalRef = this._modalService.open(this.confirmationModal, {
      scrollable: false,
      size: "md",
      centered: false,
    });
  }

  onDecline() {
    this.requestDecline = true;
    this.modalTitle = "Decline Attendance";
    this.modalRef = this._modalService.open(this.confirmationModal, {
      scrollable: false,
      size: "md",
      centered: false,
    });
  }

  showError(message: string) {
    //error notification
    this.alertType = "danger";
    this._message.next(this.message);
    this.message = message;
  }

  showSuccess(message: string) {
    this.alertType = "success";
    this._message.next(this.message);
    this.message = message;
  }

  submitConfirmation() {
    this._modalService.dismissAll();
    this.loaderService.showProgress.next(true);

    const data: AttendanceApproval = {
      empUuid: this.requests[this.currentIndex].employee.uuid,
      year: this.requests[this.currentIndex].year,
      month: this.requests[this.currentIndex].month,
      status: this.requestDecline
        ? AttendanceStatus.CANCELED
        : AttendanceStatus.COMPLETED,
      weeks: this.checkedWeeks,
      remark: this.declineReason,
    };

    this.timeSheetService
      .approveWorkLog(this.authService.currentUser().employeeUuid, data)
      .subscribe({
        next: (resp) => {
          console.log(resp);
          this.loaderService.showProgress.next(false);
          //success notification
          this.alertType = "success";
          this._message.next(this.message);
          this.message = "Attendance Approved successfuly!";
        },
        error: (err) => {
          console.log(err);
          this.loaderService.showProgress.next(false);
          //error notification
          this.alertType = "danger";
          this._message.next(this.message);
          this.message = "Error sending  your request! please try again later";
        },
      });
  }

  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }

  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
    //this._search$.next();
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._State, patch);
    this._search$.next();
  }

  get startIndex() {
    return this._State.startIndex;
  }

  get total$() {
    return this._totalRequests$.asObservable();
  }

  get endIndex() {
    return this._State.endIndex;
  }

  get totalRecords() {
    return this._State.totalRecords;
  }

  get page() {
    return this._State.page;
  }

  get pageSize() {
    return this._State.pageSize;
  }

  get searchTerm() {
    return this._State.searchTerm;
  }

  set page(page: number) {
    this._set({ page });
  }
  _search(): Observable<searchResult> {
    const { page, searchTerm } = this._State;
    // 1. sort
    let data = this.requests;
    data = data.filter((request) => matches(request, searchTerm));
    const total = data.length;
    // 3. paginate
    this._State.totalRecords = data.length;
    this._State.startIndex = (page - 1) * this.pageSize + 1;
    this._State.endIndex = (page - 1) * this.pageSize + this.pageSize;

    if (this.endIndex > this.totalRecords) {
      this._State.endIndex = this.totalRecords;
    }
    data = data.slice(this._State.startIndex - 1, this._State.endIndex);

    return of({ data, total });
  }

  onSheetCheckedChange(isChecked: boolean, week: number) {
    if (isChecked) {
      this.checkedWeeks.push(week);
    } else {
      this.checkedWeeks = this.checkedWeeks.filter((w) => w !== week);
    }
  }

  onDetail($event) {
    this.currentIndex = $event;
    this.currentLogs = this.requests[this.currentIndex].logs.workLogs;
    this.showDetails = true;
    this.showTimesheet = false;
    this._changeDetector.detectChanges();
  }

  onTimesheet(uuid: string) {
    this.fetchAttendance(uuid);
    this.showDetails = false;
    this.showTimesheet = true;
  }

  getEmployee() {
    const empl = this.requests[this.currentIndex].employee;
    return empl.firstName + " " + empl.middleName;
  }

  onCloseDetail() {
    this.showDetails = false;
  }

  getObservable(data) {
    return of(data);
  }
}
