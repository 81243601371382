import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LayoutComponent } from "./layouts/layout.component";
import { Page401Component } from "./modules/page401/page401.component";
import { Page404Component } from "./modules/page404/page404.component";
import { HrManagerGuard } from "./core/guards/hr-manager.guard";
import { ApplicationFormComponent } from "./modules/vacancy/application-form/application-form.component";
import { ApplicationResolver } from "./core/resolvers/application.resolver";
import { VacancyResolver } from "./core/resolvers/vacancy.resolver";
import { VacancySingleResolver } from "./core/resolvers/vacancy.single.resolver";
import { AuthGuard } from "./core/guards/auth.guard";

const routes: Routes = [

  {
    path: "account",
    loadChildren: () =>
      import("./modules/account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "users",
    canActivate: [HrManagerGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import("./modules/users/users.module").then((m) => m.UsersModule),
  },

  {
    path: "job-application",
    resolve: {
      vacancy: VacancySingleResolver
    },
    component: ApplicationFormComponent
  },

  {
    path: "employee",
    component: LayoutComponent,
    loadChildren: () =>
      import("./modules/employee/employee.module").then((m) => m.EmployeeModule),
  },

  {
    path: "attendance",
    component: LayoutComponent,
    loadChildren: () =>
      import("./modules/attendance/attendance.module").then((m) => m.AttendanceModule),
  },


  {
    path: "performance",
    component: LayoutComponent,
    loadChildren: () =>
      import("./modules/performance/performance.module").then((m) => m.PerformanceModule),
  },

  //leave module
  {
    path: "leaves",
    canActivate: [AuthGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import("./modules/leave/leave.module").then((m) => m.LeaveModule),
  },


  //Dashboard module
  {
    path: "dashboard",
    component: LayoutComponent,
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module")
        .then((m) => m.DashboardModule),
  },

  //Vacancy module
  {
    path: "vacancies",
    component: LayoutComponent,
    loadChildren: () =>
      import("./modules/vacancy/vacancy.module")
        .then((m) => m.VacancyModule),
  },
  {
    path: "404",
    component: Page404Component
  },

  {
    path: "unauthorized",
    component: Page401Component
  },


  {
    path: "",
    pathMatch: "full",
    redirectTo: '/dashboard'
  },


  { path: '**', redirectTo: '/404' },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
