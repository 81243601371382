import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/services/auth/auth.service';
import { RoleBasedRoutingService } from 'app/core/services/auth/role-based-routing.service';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})

/**
 * PAges-404 component
 */
export class Page404Component implements OnInit {

  constructor(private _router: Router,
    private roleBasedRouting: RoleBasedRoutingService,
    private atuhService: AuthService) { }

  ngOnInit(): void {
  }

  routeHome() {
    this.roleBasedRouting.routeToLanding(this.atuhService.currentUser())

  }
}
