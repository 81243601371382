import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AttendanceLogComponent } from './attendance-log/attendance-log.component';
import { AttendanceRequestComponent } from './attendance-request/attendance-request.component';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { ProjectResolver } from 'app/core/resolvers/project.resolver';

const routes: Routes = [
  {
    path: 'timesheet',
    canActivate: [AuthGuard],
    resolve: {
      data: ProjectResolver
    },
    component: AttendanceLogComponent
  },

  {
    path: "timesheet-request",
    canActivate: [AuthGuard],
    resolve: {
      data: ProjectResolver
    },
    component: AttendanceRequestComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AttendanceRoutingModule { }
