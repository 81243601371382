import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {VacancyService} from "../services/vacancy/vacancy.service";
import {Vacancy} from "../../modules/vacancy/vacancy.model";

@Injectable({
  providedIn: "root",
})
export class VacancySingleResolver implements Resolve<Vacancy> {
  constructor(private _vacancyServidse: VacancyService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Vacancy> {
    const uuid = route.queryParams.uuid;
    return this._vacancyServidse.getVacancy(uuid).pipe(
      catchError((error) => {
        return of("No data");
      })
    );
  }
}
