import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Question, Vacancy} from 'app/modules/vacancy/vacancy.model';
import {environment} from 'environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class VacancyService {

  constructor(private _httpClient: HttpClient) {
  }

  getAll() {
    return this._httpClient.get<Vacancy[]>(environment.baseURL + '/vacancy')
  }

  deleteVacancy(uuid: string): Observable<any> {
    return this._httpClient
      .post(environment.baseURL + `/vacancy/delete/${uuid}`, '', {responseType: "text"});

  }

  update(vacancy: Vacancy, uuid: string): Observable<any> {
    return this._httpClient
      .put(environment.baseURL + `/vacancy/update/${uuid}`, vacancy);
  }

  addVacancy(vacancy: Vacancy): Observable<any> {
    return this._httpClient
      .post(environment.baseURL + '/vacancy/create', vacancy);
  }

  addQuestion(question: Question, vacancyUuid: string) {
    return this._httpClient
      .post(environment.baseURL + `/vacancy/${vacancyUuid}/question/create`, question);
  }

  updateQuestion(question: Question, uuid: string, vacancyUUID: string) {
    return this._httpClient
      .put(environment.baseURL + `/vacancy/${vacancyUUID}/question/update/${uuid}`, question);
  }

  export(vacancyUUID: string) {
    return this._httpClient
      .get(environment.baseURL + `/vacancy/${vacancyUUID}/evaluation/export`, {responseType: "blob"});
  }

  downloadCV(vacancyUUID: string, applicationUuid: string) {
    const headers = new HttpHeaders().set('Accept', 'application/pdf, application/vnd.oasis.opendocument.text, application/msword');
    return this._httpClient
      .get(environment.baseURL + `/vacancy/${vacancyUUID}/evaluation/download/${applicationUuid}`, {
        headers,
        responseType: 'blob'
      });
  }

  publish(uuid: string) {
    return this._httpClient
      .put(environment.baseURL + `/vacancy/publish/${uuid}`, {});
  }

  getVacancy(uuid: string): Observable<any> {
    return this._httpClient
      .get(environment.baseURL + `/vacancy/get/${uuid}`);
  }

  submitApplication(formData: FormData, vacancyUUID: string) {
    return this._httpClient
      .post(environment.baseURL + `/vacancy/${vacancyUUID}/application/submit`, formData);
  }

  getApplicants(uuid: string): Observable<any> {
    return this._httpClient
      .get(environment.baseURL + `/vacancy/${uuid}/application`);
  }
}
