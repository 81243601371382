import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {NgbAlert, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BehaviorSubject, Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {User, UserPost} from "app/core/services/user/user";
import {UserService} from "app/core/services/user/user.service";
import {ActivatedRoute} from "@angular/router";
import {AccountActionEvent} from "..";
import {AuthService} from "app/core/services/auth/auth.service";
import {LoaderService} from "../../../core/services/shared/loader.service";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"],
})
export class UserListComponent implements OnInit {

  public users: User[] = [];
  userToBeUpdated: User | null;
  public users$ = new BehaviorSubject<User[]>([]);
  public modalRef: any;
  isUpdate: boolean = false;
  disableAllowed: boolean;
  _message = new Subject<string>();
  message = "";
  alertType = "";
  saving = false;
  modalTitle: string;
  userAccountEnabled: boolean;
  confirmationMessage: string;
  confirmationHeader: string;
  accountEvent: AccountActionEvent;
  @ViewChild("userModal") editModal: ElementRef;
  @ViewChild("selfClosingAlert", {static: false}) selfClosingAlert: NgbAlert;
  @ViewChild("confirmationModal") confirmModal: ElementRef;


  constructor(
    private _userService: UserService,
    private _modalService: NgbModal,
    private _authService: AuthService,
    private _loaderService: LoaderService,
    private route: ActivatedRoute
  ) {
  }


  ngOnInit(): void {
    //fetch Data from resolver if admin
    this.route.data.subscribe(
      (data: any) => {
        this.users = data.users;
        this.users$.next(this.users);
      },
      (error) => {
        console.log(error);
      }
    );

    //config alert
    this._message.pipe(debounceTime(3000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  onSubmitEvent($event: UserPost) {
    if (this.isUpdate) {
      this.updateUser($event);
    } else {
      this.addUser($event);
    }
  }

  onAddUser() {
    this.isUpdate = false;
    this.modalTitle = "Add User"

    this.modalRef = this._modalService.open(this.editModal, {
      scrollable: false,
      size: "lg",
      centered: false,
    });
  }

  refreshData() {
    this._userService.getAll().subscribe({
      next: (users) => {
        this.users = users;
        this.users$.next(users)
      },
    });
  }

  addUser(user: UserPost): void {
    this._loaderService.showProgress.next(true);
    this._userService.addUser(user).subscribe(
      () => {

        //close Modal
        this._modalService.dismissAll(this.editModal);
        this._loaderService.showProgress.next(false);

        //reload Data
        this.refreshData();

        //success notification
        this.alertType = "success";
        this._message.next(this.message);
        this.message = "User added Successfully!";
        this.saving = false;

      },
      (error) => {
        console.log(error);

        this.saving = false;
        this._modalService.dismissAll(this.editModal);
        this._loaderService.showProgress.next(false);

        //error notification
        this.alertType = "danger";
        this._message.next(this.message);
        this.message = "Error Adding  User! please try again later";
      }
    );
  }


  onAccountEvent(event: AccountActionEvent) {
    this.accountEvent = event;
    switch (event.action) {
      case "enable":
        this.userAccountEnabled = false;
        this.confirmationMessage = "Are you sure you want to activate User Account?"
        this.confirmationHeader = "Activate Account ?"
        break;
      case "disable":
        this.userAccountEnabled = true;
        this.confirmationMessage = "Are you sure you want to disable User Account?"
        this.confirmationHeader = "Disable Account ?"
        break;
      case "delete":
        this.confirmationMessage = "Are you sure you want to delete User Account?"
        this.confirmationHeader = "Delete Account ?"
        break;

        case "reset":
          this.confirmationMessage = "Are you sure you want to reset User Password?"
          this.confirmationHeader = "Reset User Password ?"
          break;
    }
    this._modalService.open(this.confirmModal, {
      scrollable: false,
      centered: true,

    });
  }


  onConfirmAction() {
    this._modalService.dismissAll(this.confirmModal);
    this.sendAccountAction();
  }

  sendAccountAction() {
    this.userToBeUpdated = this.users.find((user) => user.userId === this.accountEvent.userId);
    this._loaderService.showProgress.next(true);

    switch (this.accountEvent.action) {
      case "enable":
        this._userService.enableAccount(this.userToBeUpdated.userId).subscribe(
          () => {
            this.refreshData();
            //show notification
            this.alertType = "success";
            this._message.next(this.message);
            this.message = "Account Activated Successfully!";
            this._loaderService.showProgress.next(false);
          },
          () => {
            this._loaderService.showProgress.next(false);
            //error notification
            this.alertType = "danger";
            this._message.next(this.message);
            this.message =
              "Unable to process your request! please try again later";
          }
        );
        break;
      case "disable":
        this._userService.disableAccount(this.userToBeUpdated.userId).subscribe(
          () => {
            //reload Data
            this.refreshData();
            //show notification
            this.alertType = "success";
            this._message.next(this.message);
            this.message = "Account Disabled Successfully!";
            this._loaderService.showProgress.next(false);
          },
          (error) => {
            console.log(error);
            this._loaderService.showProgress.next(false);
            //error notification
            this.alertType = "danger";
            this._message.next(this.message);
            this.message =
              "Unable to process your request! please try again later";
          }
        );
        break;
      case "delete":
        this._userService.deleteAccount(this.userToBeUpdated.userId).subscribe(
          () => {
            //reload Data
            this.refreshData();
            //show notification
            this.alertType = "success";
            this._message.next(this.message);
            this.message = "Account Deleted Successfully!";
            this._loaderService.showProgress.next(false);
          },
          (error) => {
            console.log(error);
            this._loaderService.showProgress.next(false);
            //error notification
            this.alertType = "danger";
            this._message.next(this.message);
            this.message =
              "Unable to process your request! please try again later";
          }
        );
        break;
        case "reset":
          this._userService.resetUserPassword(this.userToBeUpdated.userId).subscribe(
            () => {
              //reload Data
              this.refreshData();
              //show notification
              this.alertType = "success";
              this._message.next(this.message);
              this.message = "Reset Password Email Sent Successfully!";
              this._loaderService.showProgress.next(false);
            },
            (error) => {
              console.log(error);
              this._loaderService.showProgress.next(false);
              //error notification
              this.alertType = "danger";
              this._message.next(this.message);
              this.message =
                "Unable to process your request! please try again later";
            }
          );
        break;
    }
  }

  updateUser(user: UserPost): void {
    this._loaderService.showProgress.next(true);
    this._userService.updateUser(user, this.userToBeUpdated.userId).subscribe(
      (data) => {
        //patch Data
        this.refreshData();
        //close Modal
        this._modalService.dismissAll(this.editModal);
        this._loaderService.showProgress.next(false);
        //success notification
        this.alertType = "success";
        this._message.next(this.message);
        this.message = "User Info Updated Successfully!";
        this.saving = false;

      },
      (error) => {
        console.log(error);
        this.saving = false;
        this._modalService.dismissAll(this.editModal);
        this._loaderService.showProgress.next(false);

        //error notification
        this.alertType = "danger";
        this._message.next(error.message);

      }
    );

  }


  onEditUser(userID: string) {

    this.isUpdate = true;
    this.modalTitle = "Update User"
    this.userToBeUpdated = this.users.find((user) => user.userId == userID);
    this.modalRef = this._modalService.open(this.editModal, {
      scrollable: false,
      size: "lg",
      centered: false,
    });

  }
}

