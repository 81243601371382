import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../services/auth/auth.service';
import {Router} from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  private accessTokenSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);

  constructor(private _authService: AuthService,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    return next.handle(request).pipe(
      catchError((error) => {
        // Catch "401
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401
        ) {
          //check if url is login or refresh token
          if (
            !(request.url.includes('auth'))
          ) {
            this._authService.getUserData(localStorage.getItem("userId"));

          } else {
            return throwError(error);
          }
        } else {

          return throwError(error);
        }
      })
    )

  };
}
