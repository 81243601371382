import { Employee } from "../employee/Employee";

export interface LeaveType {
  uuid: string;
  name: string;
  leaveType:string;
  allowedBalance: number;
}

export interface Leave {
  days: number
  fromDate: string
  toDate: string
  leaveAddress: string
  phoneNumber: string
  remark: any
  employeeUuid: string
  leaveTypeUuid: string
  uuid: string
  status: number
  statusDescription: string
  leaveType: string
  employee: Employee
  approver: Employee
  verifiedBy: Employee
  approvedOn: string
  verifiedOn: string
  managerRemark: string
  hrRemark: string
  approverUuid: string
}

export interface LeaveRequest {
  uuid: string
  employee: string
  type: string
  fromDate: string
  toDate: string
  status: string
  days: number
  reason: string
}
export enum LeaveStatusEnum {
  PENDING = "0",
  APPROVED_MANAGER = "1",
  HR_APPROVED = "2",
  REJECTED = "3"
}
