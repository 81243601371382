<div class="container-boxed col-lg-11 mx-auto">
  <div class="page-title-box mb-0 d-flex justify-content-between">
    <div class="col-sm-4">
      <app-page-title title="Leave Requests"></app-page-title>
    </div>
    <div class="col-sm-8">
      <ngb-alert
        #selfClosingAlert
        (closed)="message = ''"
        *ngIf="message"
        [dismissible]="false"
        [type]="alertType"
      >
        {{ message }}
      </ngb-alert>
    </div>
  </div>

  <app-leave-request-table
    [employeeType]="'manager'"
    [leaves]="leaveRequests$ | async"
    (onApprove)="onApprove($event)"
    (onDecline)="onDecline($event)"
  >
  </app-leave-request-table>

  <ng-template #confirmationModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">{{ modalTitle }}</h5>
      <button
        (click)="modalRef.close('Close click')"
        aria-label="Close"
        class="btn-close"
        type="button"
      ></button>
    </div>

    <div class="modal-body modal-address">
      <div class="form-group">
        <div *ngIf="requestDecline" class="form-group">
          <label for="declineReasonTextarea">Decline Reason:</label>
          <textarea
            placeholder="Enter decline reason..."
            class="form-control border-info"
            id="declineReasonTextarea"
            rows="3"
            [(ngModel)]="declineReason"
            name="declineReason"
          ></textarea>
        </div>

        <div *ngIf="!requestDecline">
          <p>Are you sure you want to approve this leave request?</p>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="modalRef.dismiss()"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="requestDecline && !declineReason"
        (click)="submitConfirmation()"
      >
        Submit
      </button>
    </div>
  </ng-template>
</div>
