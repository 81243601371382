<div class="container-boxed col-lg-11 mx-auto">

  <div class="page-title-box mb-0 d-flex justify-content-between">
    <div class="col-sm-4">
      <app-page-title title="My Leave"> </app-page-title>
    </div>
    <div class="col-sm-8">
      <ngb-alert
        #selfClosingAlert
        (closed)="message = ''"
        *ngIf="message"
        [dismissible]="false"
        [type]="alertType"
        >{{ message }}
      </ngb-alert>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-3">
      <app-stat
        [icon]="'bx bx-calendar-check'"
        [title]="'Avalable Leaves'"
        [value]="statistics.initialBalance"
        [bgClass]="'bg-primary bg-soft'"
        [textClass]="'text-primary'"
        [isCurrency]="false"
      ></app-stat>
    </div>

    <div class="col-sm-3">
      <app-stat
        [icon]="'bx bx-calendar-check'"
        [title]="'Unused Leaves'"
        [value]="statistics.remainingBalance"
        [bgClass]="'bg-info bg-soft'"
        [textClass]="'text-info'"
        [isCurrency]="false"
      ></app-stat>
    </div>

    <div class="col-sm-3">
      <app-stat
        [icon]="'bx bx-time-five'"
        [title]="'Pending Requests'"
        [bgClass]="'bg-secondary bg-soft'"
        [textClass]="'text-secondary'"
        [value]="statistics.pending"
        [isCurrency]="false"
      ></app-stat>
    </div>

    <div class="col-sm-3">
      <!--gross revenue-->
      <app-stat
        [icon]="'bx bx-x-circle'"
        [title]="'Rejected Leaves'"
        [bgClass]="'bg-danger bg-soft'"
        [textClass]="'text-danger'"
        [value]="statistics.rejected"
        [isCurrency]="false"
      ></app-stat>
    </div>
  </div>

  <app-leave-request-table
  [employeeType]="'employee'"
  [leaves]="leaveRequests$ | async"
  (onAdd)="onAddLeaveRequest()"
  (onUpdate)="onUpdateLeaveRequest($event)"
  >

  </app-leave-request-table>



  <ng-template #leaveRequestModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">{{ modalTitle }}</h5>
      <button
        (click)="modalRef.close('Close click')"
        aria-label="Close"
        class="btn-close"
        type="button"
      ></button>
    </div>

    <div class="modal-body modal-address">
      <form [formGroup]="requestForm" (ngSubmit)="submitForm()">
        <div class="row">

            <div class="form-group mb-2">
              <label for="leaveType">Leave Type</label>
              <ng-select
                id="leaveType"
                formControlName="leaveTypeUuid"
                [items]="leaveTypes"
                [closeOnSelect]="true"
                bindLabel="name"
                bindValue="uuid"
                placeholder="Select leave type"
              ></ng-select>
            </div>

            <div class="mb-2 form-group">
              <label>From Date<span class="text-danger"> *</span> </label>
              <input
                #fdd="ngbDatepicker"
                (click)="fdd.toggle()"
                [ngClass]="{
                  'is-invalid':
                    (form.fromDate.dirty || submit) && form.fromDate.errors
                }"
                autocomplete="off"
                class="form-control date-cursor"
                formControlName="fromDate"
                ngbDatepicker
                placeholder="Enter Starting date"
                type="text"
              />
              <div
                *ngIf="(form.fromDate.dirty || submit) && form.fromDate.errors"
                class="invalid-feedback"
              >
                <span *ngIf="form.fromDate.errors"
                  >Please provide a valid date.</span
                >
              </div>
            </div>

            <div class="mb-2 form-group">
              <label>To Date<span class="text-danger"> *</span> </label>
              <input
                #tdd="ngbDatepicker"
                (click)="tdd.toggle()"
                [ngClass]="{
                  'is-invalid':
                    (form.toDate.dirty || submit) && form.toDate.errors
                }"
                autocomplete="off"
                class="form-control date-cursor"
                formControlName="toDate"
                ngbDatepicker
                placeholder="Enter ending date"
                type="text"
              />
              <div
                *ngIf="(form.toDate.dirty || submit) && form.toDate.errors"
                class="invalid-feedback"
              >
                <span *ngIf="form.toDate.errors"
                  >Please provide a valid date.</span
                >
              </div>
              '
            </div>

            <div class="mb-3">
              <label>Description <span class="text-danger"> *</span></label>
              <textarea
                [ngClass]="{
                  'is-invalid':
                    (form.remark.dirty || submit) &&
                    form.remark.errors
                }"
                class="form-control edit-input"
                formControlName="remark"
                placeholder="Enter Description"
                type="text"
              >
              </textarea>
            </div>

        </div>

        <div class="d-flex justify-content-end">
          <button type="submit" class="btn btn-primary text-end">
            Request Now
          </button>
        </div>
      </form>
    </div>
  </ng-template>
</div>
