<div class="col-sm-11 mx-auto">
  <div class="d-flex justify-content-between">
    <app-page-title title="TimeSheet" [breadcrumbItems]="breadCrumbItems">
    </app-page-title>

    <div class="btn-group">
      <button (click)="onLog('start')" class="btn btn-primary">
        <i class="mdi mdi-clock-outline"></i> Check In
      </button>

      <button (click)="onLog('end')" class="btn btn-secondary">
        Check Out
      </button>
    </div>
  </div>

  <div class="col-sm-8">
    <ngb-alert
      #selfClosingAlert
      (closed)="message = ''"
      *ngIf="message"
      [dismissible]="false"
      [type]="alertType"
    >
      {{ message }}
    </ngb-alert>
  </div>

  <div class="card shadow-lg mt-2">
    <div class="card-body row col-sm-6 d-flex justify-content-end">
      <div class="col-md-8 row">
        <div class="col-sm-6">
          <label>From Date</label>
          <input
            type="text"
            class="form-control date-cursor"
            [(ngModel)]="fromDate"
            (click)="fdd.toggle()"
            (ngModelChange)="onFromDateChange($event)"
            ngbDatepicker
            #fdd="ngbDatepicker"
            autocomplete="off"
          />
        </div>
        <div class="col-sm-6">
          <label>To Date</label>
          <input
            type="text"
            class="form-control date-cursor"
            (click)="tdd.toggle()"
            ngbDatepicker
            disabled="true"
            [(ngModel)]="toDate"
            #tdd="ngbDatepicker"
            autocomplete="off"
          />
        </div>
        <div class="mt-3">
          <strong class="mt-2">Week: {{ weekNumber }}</strong>
        </div>
      </div>

      <div class="col-md-4"></div>
    </div>
  </div>

  <div *ngIf="showSummary">
    <app-monthly-sheet
      (onSuccess)="showSuccess($event)"
      (onError)="showError($event)"
      [year]="year"
      [projects]="projects"
      [month]="month"
      [weekHrs]="weekHrs"
      [totalCheckInTimeMinute]="totalCheckedIntime"
      [week]="weekNumber"
      [workLogs$]="log$"
    ></app-monthly-sheet>
  </div>
</div>

<ng-template #confirmationModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Log attendance</h5>
    <button
      (click)="modalRef.close('Close click')"
      aria-label="Close"
      class="btn-close"
      type="button"
    ></button>
  </div>

  <div class="modal-body modal-address">
    <div class="form-group">
      <div>
        <p>Are you sure you want to log office time?</p>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="modalRef.dismiss()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="submitConfirmation()"
    >
      Submit
    </button>
  </div>
</ng-template>
