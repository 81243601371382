import {Component, Inject, OnInit} from '@angular/core';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateCustomParserFormatter} from './core/helpers/ngb-date-formatter';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {LoaderService} from "./core/services/shared/loader.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{provide: NgbDateParserFormatter, useFactory: () => new NgbDateCustomParserFormatter(),}]
})
export class AppComponent implements OnInit {
  private style: HTMLLinkElement;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private _loaderService: LoaderService
  ) {
  }

  ngOnInit() {

    this.router.events.subscribe({
      next: (routerEvent) => {

        if (routerEvent instanceof NavigationStart) {

          this._loaderService.showProgress.next(true);

        } else if (routerEvent instanceof NavigationEnd ||
          routerEvent instanceof NavigationCancel ||
          routerEvent instanceof NavigationError
        ) {
          this._loaderService.showProgress.next(false);
        }
      }
    });
  }

}
