import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {UserListComponent} from './user-list/user-list.component';
import {UserResolver} from "../../core/resolvers/user.resolver";

const routes: Routes = [

  {
    path: "",
    resolve: {
      users: UserResolver
    },
    component: UserListComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule {
}
