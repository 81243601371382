<div class="row mb-md-2  mt-3 ">
  <div class="col-sm-12 col-md-3">
    <div class="dataTables_length">
      <label class="d-inline-flex align-items-center">Show
        <select [(ngModel)]="pageSize" aria-controls="tickets-table" class="form-control form-control-sm mx-2"
                name="tickets-table_length"
                name="pageSize">
          <option [ngValue]="10">10</option>
          <option [ngValue]="25">25</option>
          <option [ngValue]="50">50</option>
        </select> entries</label>
    </div>
  </div>
  <div class="col-md-9 row  col-sm-12 d-flex justify-content-end">
    <div class="mb-3 col-md-4 col-sm-12  ">
      <label><i class="bx bx-filter-alt text-primary"></i> Status</label>
      <ng-select [(ngModel)]="statusSearchTerm" [clearable]="true" [items]="accountStatus" bindLabel="name"
                 bindValue="enabled" class="edit-input" placeholder="All">
      </ng-select>
    </div>
    <!-- Search -->
    <div class="col-sm-12 col-md-4 ">

      <label>Search</label>
      <input [(ngModel)]="searchTerm" aria-controls="tickets-table" class="form-control form-control " name="searchTerm"
             type="text">

    </div>
  </div>

  <!-- End search -->
</div>
<!-- Table -->
<div class="table-responsive">
  <table class="table table-sm table-hover">
    <thead>
    <tr>
      <th scope="col" style="width: 50px;"></th>
      <th sortable="lastName"> Name</th>
      <th sortable="status">Status</th>
      <th sortable="telephone">Phone</th>
      <th sortable="permission">Roles</th>
      <th style="width: 20%;">Actions</th>
    </tr>
    </thead>
    <tbody *ngFor="let user of users$ | async ;let i=index">

    <tr>

      <td class="align-middle">
        <div class="avatar-xs">
              <span *ngIf="user.firstName && user.lastName" class="avatar-title rounded-circle">
                {{user.firstName.charAt(0)}}{{user.lastName.charAt(0)}}
              </span>
        </div>
      </td>

      <td class="align-middle text-truncate ">
        <ngb-highlight [result]="user.fullName" [term]="searchTerm">
        </ngb-highlight>
      </td>

      <td class="align-middle text-truncate">

        <div *ngIf="user.active">
          Active <i class="mdi mdi-check-all success-icon"></i>
        </div>

        <div *ngIf="!user.active">
          Disabled <i class="warning-icon fas fa-ban"></i>
        </div>

      </td>

      <td class="align-middle text-truncate ">
        <ngb-highlight *ngIf="user.phoneNumber" [result]=" user.phoneNumber" [term]="searchTerm">
        </ngb-highlight>
      </td>

      <td class="align-middle text-truncate ">
         <span *ngFor="let role of user.roles" class="badge  badge-soft-info m-1 font-size-12">
              {{role}}
            </span>
      </td>


      <td (click)="$event.stopPropagation()" class="align-middle">
        <div class="btn-group">
          <button (click)="onEdit(user.userId)" class="btn btn-sm btn-outline-primary ">

            Edit
          </button>

          <button (click)="onAccountAction(user.userId, 'disable')" *ngIf="user.active"
                  class="btn btn-sm btn-outline-warning ">

            Disable
          </button>

          <button (click)="onAccountAction(user.userId, 'enable')" *ngIf="!user.active"
                  class="btn btn-sm btn-outline-warning ">

            Enable
          </button>

          <button (click)="onAccountAction(user.userId, 'reset')"
                  class="btn btn-sm btn-outline-info ">

            Reset
          </button>

          <button (click)="onAccountAction(user.userId, 'delete')"
                  class="btn btn-sm btn-outline-danger ">

            Delete
          </button>
        </div>
      </td>
    </tr>


    </tbody>
  </table>
</div>
<!-- End table -->
<div class="row justify-content-md-between align-items-md-center mt-2">
  <div class="col-sm-12 col-md-5">
    <div aria-live="polite" class="dataTables_info mb-2" id="tickets-table_info" role="status">Showing
      {{startIndex}} to
      {{endIndex}} of {{totalRecords}}
      entries
    </div>
  </div>
  <!-- Pagination -->
  <div class="col-sm-12 col-md-5">
    <div class="text-md-right float-md-end pagination-rounded">
      <ngb-pagination [(page)]="page" [collectionSize]="total$ | async" [pageSize]="pageSize">
      </ngb-pagination>
    </div>
  </div>
  <!-- End Pagination -->
</div>
