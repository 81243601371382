import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators,} from "@angular/forms";
import {User, UserPost} from 'app/core/services/user/user';
import {RolesEnum} from "../../../shared/models/Roles";

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  userForm: FormGroup;
  formInitialValue: any;
  actionBtnName: string = "Add"
  formChanged: boolean = false;
  submit: boolean;
  saving: boolean;
  roleList: any[] = [];
  genderList: string[] = [];
  @Input() isUpdate: boolean;
  @Input() user: User
  @Output() onSubmit: EventEmitter<UserPost> = new EventEmitter<UserPost>();

  constructor(private _fb: FormBuilder) {
  }

  get form() {
    return this.userForm.controls;
  }

  ngOnInit(): void {
    for (let rolesEnumKey in RolesEnum) {
      this.roleList.push({
        name: rolesEnumKey
      })
    }
    this.genderList.push("FEMALE");
    this.genderList.push("MALE");
    if (this.isUpdate) {
      this.actionBtnName = "Save"
    }

    this.submit = false;
    this.saving = false;
    this.userForm = this._fb.group({

      firstName: new FormControl(null,
        {
          validators: [Validators.required],
          updateOn: 'change'

        }),
      middleName: new FormControl(null,
        {
          validators: [Validators.required],
          updateOn: 'change'

        }),
      lastName: new FormControl("", {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      gender: new FormControl("", {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      email: new FormControl("", {
        validators: [Validators.required, Validators.email],
        updateOn: 'change'
      }),
      roles: new FormControl("", {
        validators: [Validators.required],
        updateOn: 'change'
      }),

      phoneNumber: new FormControl("", {
        validators: [Validators.required],
        updateOn: 'change'
      }),

    });

    //subscribe to form change
    this.userForm.valueChanges.subscribe((value) => {

      if (this.formInitialValue) {

        const isFormChanged = Object.keys(this.formInitialValue).some(key => this.userForm.value[key] !==
          this.formInitialValue[key]);

        this.formChanged = isFormChanged;
      } else {
        this.formChanged = true;
      }

    });

    this.prepareForm();
  }

  resetForm(form: FormGroup) {

    form.reset();

    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null);
    });
  }

  prepareForm(): void {
    if (this.isUpdate) {
      //patch form
      this.userForm.patchValue(this.user)
      this.formInitialValue = this.userForm.value;
      this.formChanged = false;
    }

  }

  validSubmit() {
    // console.log(this.userForm.value)
    this.submit = true;
    if (this.userForm.valid) {
      this.saving = true;
      this.userForm.disable();
      let formD: UserPost = this.userForm.value;
      //emit submitEvent
      this.onSubmit.emit(formD);
    } else {
      console.log(this.userForm.errors)
    }
  }

}
