<div class="card mini-stats-wid radius-20 shadow-lg zoom-effect">
  <div class="bg-holder bg-card"></div>
  <div class="card-body">
    <div class="d-flex justify-content-between">
      <div class="">
        <p class="font-weight-semibold">{{ title }}</p>
        <h4>{{ isCurrency ? (value | currency) : (value | number) }}</h4>
      </div>

      <div class="avatar-sm me-3">
        <span class="avatar-title rounded-circle font-size-24" [ngClass]="[bgClass, textClass]">
          <i class="{{ icon }} font"></i>
        </span>
      </div>
    </div>
  </div>
</div>
