import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, forkJoin } from 'rxjs';
import { Leave, LeaveType } from './leave';
import { LeaveStatistics } from './LeaveStat';
import { LeaveBalance } from './leabe-balance';

@Injectable({
  providedIn: 'root'
})
export class LeaveService {

  constructor(private _htpp: HttpClient) { }

  getLeaveRequests(userType: string, userUuid: string): Observable<Leave[]> {
    let params = new HttpParams();
    params = params.set('userUuid', userUuid);
    return this._htpp.get<Leave[]>(environment.baseURL + `/leave/request/${userType}`, { params: params });
  }
  getLeaveBalance(userUuid: string): Observable<LeaveBalance[]> {
    let params = new HttpParams();

    params = params.set('empUuid', userUuid);
    return this._htpp.get<LeaveBalance[]>(environment.baseURL + `/leave/balance`, { params: params });
  }

  requestLeave(data: any,) {
    return this._htpp.post(environment.baseURL + `/leave/request`, data);
  }

  updateLeaveRequest(data: any,uuid:string) {
    return this._htpp.put(environment.baseURL + `/leave/update/${uuid}`, data);
  }
  getLeaveStatistics(emplUuid: string): Observable<LeaveStatistics> {
    return this._htpp.get<LeaveStatistics>(environment.baseURL + `/leave/dashboard/${emplUuid}`);
  }

  approveLeaveRequest(userType: string, uuid: string, data:any) {
    return this._htpp.put(`${environment.baseURL}/leave/approve/${userType}/${uuid}`, data)
  }

  getLeaveTypes(): Observable<LeaveType[]> {
    return this._htpp.get<LeaveType[]>(environment.baseURL + `${"/leave"}`);
  }

  fetchEmployeeLeaveData(emplUuid: string): Observable<[Leave[], LeaveStatistics]> {
    const leaves$ = this.getLeaveRequests('employee', emplUuid);
    const leaveStat$ = this.getLeaveStatistics(emplUuid);

    return forkJoin([leaves$, leaveStat$]);
  }


}
