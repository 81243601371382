import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'environments/environment';
import {UserPost} from './user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _httpClient: HttpClient) {
  }

  getAll(): Observable<any> {

    return this._httpClient
      .get(environment.baseURL + '/user');
  }

  addUser(user: UserPost): Observable<any> {
    return this._httpClient
      .post(environment.baseURL + '/user/create', user);
  }

  getById(id: number): Observable<any> {
    return this._httpClient
      .get(environment.baseURL + `/user/${id}`);
  }

  enableAccount(userID: string): Observable<string> {
    return this._httpClient
      .put(environment.baseURL + `/user/activate/${userID}`, '', {responseType: "text"});
  }

  disableAccount(userID: string): Observable<string> {
    return this._httpClient
      .put(environment.baseURL + `/user/deactivate/${userID}`, '', {responseType: "text"});
  }

  resetUserPassword(userID: string): Observable<string> {
    return this._httpClient
      .put(environment.baseURL + `/user/resetPassword/${userID}`, '', {responseType: "text"});
  }


  deleteAccount(userID: string): Observable<string> {
    return this._httpClient
      .post(environment.baseURL + `/user/delete/${userID}`, '', {responseType: "text"});
  }

  updateUser(user: UserPost, userId: string): Observable<any> {
    return this._httpClient
      .put(environment.baseURL + `/user/update/${userId}`, user);

  }

  changeMyPassword(data: any) {
    return this._httpClient
      .put(environment.baseURL + `/self/changePassword`, data);
  }
}
