<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">

      <div class="navbar-brand-box">

        <a class="logo " routerLink="/">
          <span class="logo-sm">
            <img alt="" height="70" src="assets/images/main-logo.png">
          </span>
          <span class="logo-lg">
            <img alt="" height="70" src="assets/images/main-logo.png">
          </span>
        </a>
      </div>

      <button (click)="toggleMobileMenu($event)" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
              type="button">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->


    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button aria-expanded="false" aria-haspopup="true" class="btn header-item noti-icon" data-toggle="dropdown"
                id="page-header-search-dropdown" ngbDropdownToggle type="button">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div aria-labelledby="page-header-search-dropdown" class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
             ngbDropdownMenu>
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input aria-label="Recipient's username" class="form-control" placeholder="Search ..." type="text">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>


      <div class="dropdown d-inline-block" ngbDropdown>
        <button class="btn header-item noti-icon" id="page-header-notifications-dropdown" ngbDropdownToggle
                type="button">
          <i class="bx bx-bell bx-tada"></i>
          <span class="badge bg-danger rounded-pill">1</span>
        </button>

      </div>


      <div
        class="bg-primary bg-soft avatar-circle border-primary rounded-circle d-flex justify-content-center mt-3">
        <P
          class="mt-2 fw-bold text-black"> {{currentUser.firstName.charAt (0)}}{{currentUser.lastName.charAt (0)}} </P>
      </div>



      <div class="dropdown d-inline-block" ngbDropdown>

        <button class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle type="button">

          <span class="d-none d-xl-inline-block ms-1 fw-bold text-black">{{username}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>

        </button>

        <div class="dropdown-menu dropdown-menu-end menu" ngbDropdownMenu>

          <a (click)="onProfile()" class="dropdown-item d-block"><i
            class="bx bx-user font-size-16 align-middle me-1"></i> My Profile </a>


          <a (click)="onPasswordChange()" class="dropdown-item d-block" href="javascript: void(0);"><i
            class="bx bx-key font-size-16 align-middle me-1"></i>Change Password </a>

          <div class="dropdown-divider"></div>
          <a (click)="logout()" class="dropdown-item text-danger" href="javascript: void(0);"><i
            class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            Log out</a>
        </div>

      </div>

    </div>
  </div>
</header>

<ng-template #profileModal let-modal>

  <div class="modal-header">
    <h5 class="modal-title mt-0">Change Password</h5>
    <button (click)="modalRef.close('Close click')" aria-label="Close" class="btn-close" type="button">
    </button>
  </div>

  <div class="modal-body modal-address">

    <ngb-alert #selfClosingAlert (closed)="message = ''" *ngIf="message" [dismissible]="false" [type]="alertType">
      {{ message }}
    </ngb-alert>

    <form (ngSubmit)="passwordChangeSubmit()" [formGroup]="passwordChangeForm" class="needs-validation">
      <div class="mt-4 text-center col-sm-10 mx-auto">


        <div class="row mb-2 justify-items-end">
          <label class="col-sm-3 col-form-label text-end">Current
            Password</label>
          <div class="col-sm-9">
            <div class="input-group auth-pass-inputgroup">
              <input
                [ngClass]="{'is-invalid' :(passwordForm.oldPassword.dirty || passwordFormSubmit )  && passwordForm.oldPassword.errors}"
                [type]="visiblePassword ? 'text' :'password'"
                class="form-control edit-input" formControlName="oldPassword"
                placeholder="Enter Current Password">

              <button (click)="togglePasswordField()" class="btn btn-light ms-0" type="button"
              >
                <i [ngClass]=" visiblePassword ? 'mdi mdi-eye-off': 'mdi mdi-eye-outline'
                        "></i>
              </button>
              <div *ngIf="passwordForm.oldPassword.dirty  && passwordForm.oldPassword.errors"
                   class="invalid-feedback text-start">
                <span *ngIf="passwordForm.oldPassword.errors">Please provide your Current Password.</span>

              </div>
            </div>

          </div>

        </div>

        <div class="row mb-2 justify-items-end">
          <label class="col-sm-3 col-form-label text-end">New
            Password</label>
          <div class="col-sm-9">
            <div class="input-group auth-pass-inputgroup">
              <input
                [ngClass]="{'is-invalid' :(passwordForm.password.dirty || passwordFormSubmit )  && passwordForm.password.errors}"
                [type]="visiblePassword ? 'text' :'password'"
                class="form-control edit-input" formControlName="password"
                placeholder="Enter New Password">

              <button (click)="togglePasswordField()" class="btn btn-light ms-0" type="button"
              >
                <i [ngClass]=" visiblePassword ? 'mdi mdi-eye-off': 'mdi mdi-eye-outline'
                        "></i>
              </button>
              <div *ngIf="passwordForm.password.dirty  && passwordForm.password.errors"
                   class="invalid-feedback text-start">
                <span *ngIf="passwordForm.password.errors">Please provide your New Password.</span>

              </div>
            </div>

          </div>

        </div>

        <div class="row mb-2 justify-items-end">
          <label class="col-sm-3 col-form-label text-end">Confirm
            Password</label>
          <div class="col-sm-9">
            <div class="input-group auth-pass-inputgroup">
              <input
                [ngClass]="{'is-invalid' :(passwordForm.confirmPassword.dirty || passwordFormSubmit )  && passwordForm.confirmPassword.errors}"
                [type]="visiblePassword ? 'text' :'password'"
                class="form-control edit-input" formControlName="confirmPassword"
                placeholder="Confirm Password">

              <button (click)="togglePasswordField()" class="btn btn-light ms-0" id="password-addon"
                      type="button">
                <i [ngClass]=" visiblePassword ? 'mdi mdi-eye-off': 'mdi mdi-eye-outline'
                        "></i>
              </button>
              <div *ngIf="passwordForm.confirmPassword.errors" class="invalid-feedback text-start">
                <span *ngIf="passwordForm.confirmPassword.errors.required">Please confirm your Password.</span>
                <span *ngIf="passwordForm.confirmPassword.errors.confirmedValidator">Password doesn't
                        match.</span>

              </div>
            </div>

          </div>

        </div>


        <div class=" mt-4 d-flex justify-content-end">
          <div class="d-flex flex-wrap gap-2">
            <button [disabled]="savingPassword " class="btn btn-primary" type="submit" type="submit">Save
              Changes
              <span *ngIf="savingPassword" aria-hidden="true" class="spinner-border spinner-border-sm"
                    role="status"></span>
            </button>

            <button (click)="modalRef.close('Close click')" class="btn btn-secondary"
                    type="button">Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
