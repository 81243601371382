import { Employee } from "app/core/services/employee/Employee";

export interface Attendance {
  attendanceDate: string;
  startTime: string;
  endTime: string;
  employeeUuid: string;
  totalHrsMinute: string;
  ip: string;
  uuid: string;
  status: string;
  description: string;
  remark: string;
}
export interface Day {
  date: string;
  time: string;
  onLeave: boolean;
  description: string | null;
}

export interface Log {
  projectUuid: string | null;
  projectName: string | null;
  days: Day[];
  totalHrsMinute: number;
}

export enum AttendanceStatus {
  SAVED,
  SUBMITTED,
  COMPLETED,
  CANCELED,
  REJECTED,
}

export interface WorkLog {
  week: number;
  totalHrsMinute: number;
  totalCheckInTimeMinute: number;
  status: "SAVED" | "SUBMITTED" | "COMPLETED" | "CANCELED" | "REJECTED" | null;
  logs: Log[];
}

export interface AttendanceLog {
  month: number;
  monthName: string;
  year: number;
  empUuid: string;
  workLogs: WorkLog[];
}

export interface AttendaceSubmitDto {
  year: number;
  month: number;
  weeks: number[];
  empUuid: string;
}

export interface WorkLogRequest {
  year: number;
  month: number;
  monthName: string;
  employee: Employee;
  logs: AttendanceLog;
}

export interface AttendanceApproval {
  empUuid: string;
  year: number;
  month: number;
  weeks: number[];
  status: number;
  remark: string;
}
