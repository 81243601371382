<div class="row col-sm-11 mx-auto m-3 card radius-10 shadow-lg row-height d-flex" style="overflow: auto">

  <div class="col-xl-4 col-lg-4 content-left">
    <div class=" content-left-wrapper  ">
      <!-- <a href="index.html" id="logo"><img alt="" height="90" src="assets/images/main-logo.png" width="200"></a> -->

      <div>
        <figure><img alt="" class="img-fluid" height="200" src="assets/images/main-logo.png" width="200"></figure>
        <h4 class="text-white font-weight-semibold">We are Hiring!</h4>
        <p class="text-justify  font-size-11">Welcome to our job application form! We're excited to learn more about you
          and your
          qualifications. To ensure
          that your application is complete and accurate, we've divided the form into three steps. Please fill out each
          step carefully and honestly.</p>
        <p class="text-justify text-warning font-size-13">
          Please note that all fields are required, and incomplete or inaccurate information may result in your
          application being rejected
        </p>
      </div>
      <div class="copy">© 2023 Reach Ethiopia</div>
    </div>
    <!-- /content-left-wrapper -->
  </div>
  <!-- /content-left -->
  <div class="col-xl-8 col-lg-8 " id="start">

    <div class="container-fluid col-sm -11 mx-auto">

      <div class=" d-flex mt-3 justify-content-between border-bottom mb-3">
        <div class="col-sm-4">
          <app-page-title title="application form">
          </app-page-title>
        </div>
        <div class="col-sm-8 text-end">
          <h5 class="text-primary font-weight-semibold"><i class="bx bx-briefcase"></i>{{vacancy.jobTitle}}</h5>
        </div>
      </div>

      <ngb-alert
        #selfClosingAlert
        (closed)="message = ''"
        *ngIf="message"
        [dismissible]="false"
        [type]="alertType"
      >{{ message }}
      </ngb-alert>


      <div *ngIf="!finished" class="row">
        <div class="col-sm-3">
          <div class="wizard-steps">
            <div *ngFor="let step of steps; let i = index" class="wizard-step">
              <div [class.completed]="i < currentStep" [class.current]="i === currentStep - 1"
                   class="wizard-step-number">{{ i + 1 }}</div>
              <div class="wizard-step-label">{{ step.label }}</div>
              <div *ngIf="i !== steps.length - 1" [class.completed]="i < currentStep - 1 && i > 0"
                   class="wizard-step-line"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-9" id="wizard-body-div">
          <div class="card" style="overflow:auto">
            <div class="card-body" style="overflow:auto">
              <form (ngSubmit)="submitForm()" [formGroup]="applicationForm">
                <div *ngIf="currentStep === 1" [hidden]="currentStep !== 1" class="" formGroupName="step1">
                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <label for="firstName">First Name</label>
                      <input [ngClass]="{'is-invalid' :(applicationForm.get('step1').get('firstName').touched
                              && applicationForm.get('step1').get('firstName').errors)}" class="form-control rounded"
                             formControlName="firstName"
                             id="firstName"
                             placeholder="Enter First Name" type="text">

                      <div *ngIf="applicationForm.get('step1').get('firstName').hasError('required')"
                           class="invalid-feedback">
                        <p>First Name is required</p>
                      </div>

                    </div>
                    <div class="col-sm-6">
                      <label for="middleName">Middle Name</label>
                      <input [ngClass]="{'is-invalid' :(applicationForm.get('step1').get('middleName').touched
                              && applicationForm.get('step1').get('middleName').errors)}" class="form-control"
                             formControlName="middleName"
                             id="middleName"
                             placeholder="Enter Middle Name" type="text">
                      <div *ngIf="applicationForm.get('step1').get('middleName').hasError('required')"
                           class="invalid-feedback">
                        <p>Middle Name is required</p>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <label for="lastName">Last Name</label>
                      <input [ngClass]="{'is-invalid' :(applicationForm.get('step1').get('lastName').touched
                              && applicationForm.get('step1').get('lastName').errors)}" class="form-control"
                             formControlName="lastName" id="lastName"
                             placeholder="Enter Last Name"

                             type="text">
                      <div *ngIf="applicationForm.get('step1').get('lastName').hasError('required')"
                           class="invalid-feedback">
                        Last Name is required
                      </div>
                    </div>
                    <div class="col-sm-6 styled-select">
                      <label for="gender">Gender</label>
                      <select
                        [ngClass]="{'is-invalid' :(applicationForm.get('step1').get('gender').touched
                              && applicationForm.get('step1').get('gender').errors)}"
                        class="form-control" formControlName="gender" id="gender">
                        <option value="">Select Gender</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                      </select>
                      <div *ngIf="applicationForm.get('step1').get('gender').hasError('required')"
                           class="invalid-feedback">
                        Gender is required
                      </div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-sm-6">

                      <label for="email">Email</label>
                      <input [ngClass]="{'is-invalid' :(applicationForm.get('step1').get('email').touched
                              && applicationForm.get('step1').get('email').errors)}" class="form-control"
                             formControlName="email" id="email"
                             placeholder="Enter email"
                             type="email">
                      <div *ngIf="applicationForm.get('step1').get('email').hasError('required')"
                           class="invalid-feedback">
                        Email is required
                      </div>
                      <div *ngIf="applicationForm.get('step1').get('email').hasError('email')" class="invalid-feedback">
                        Invalid Email
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <label for="phoneNumber">Phone Number</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <select class="form-control country-code-dropdown" formControlName="countryCode">
                            <option value="" disabled>Select Country Code</option>
                            <option *ngFor="let code of countryCodes" [ngValue]="code">{{ code.name }} ({{ code.code }})</option>
                          </select>
                        </div>
                        <input [showMaskTyped]="true" class="form-control" formControlName="phoneNumber" id="phoneNumber" mask="000-00-00-00" type="text">
                      </div>
                      <div *ngIf="applicationForm.get('step1').get('phoneNumber').hasError('required')" class="invalid-feedback">
                        Phone Number is required
                      </div>
                    </div>

                  </div>
                  <div class=" col-sm-6 form-group  ">
                    <label>Upload Resume<br><small>(File accepted: .pdf, .doc/docx - Max file size: 150KB
                      )</small></label>
                    <div class="fileupload">
                      <input
                        (change)="onFileSelected($event)"
                        [ngClass]="{'is-invalid' :(applicationForm.get('step1').get('cv').touched
                              && applicationForm.get('step1').get('cv').errors)}"
                        accept=".pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        class="required"
                        formControlName="cv"
                        name="cv"
                        type="file">
                      <div *ngIf="applicationForm.get('step1').get('cv').hasError('required')"
                           class="invalid-feedback">
                        Resume is required
                      </div>
                    </div>

                  </div>


                </div>
                <div *ngIf="currentStep === 2" [hidden]="currentStep !== 2" class="" formGroupName="step2">
                  <div class="row mb-2">
                    <div class="col-sm-6">

                      <label for="educationType">Field of Study</label>
                      <select [ngClass]="{'is-invalid': (applicationForm.get('step2')
                      .get('educationType')
                      .touched && applicationForm.get('step2').get('educationType').errors)}"
                              class="form-control" formControlName="educationType" id="educationType">
                        <optgroup *ngFor="let category of categories" [label]="category.name">
                          <option *ngFor="let subcategory of category.subcategories" [value]="subcategory">{{ subcategory }}</option>
                        </optgroup>

                      </select>

                      <div *ngIf="applicationForm.get('step2').get('educationType').hasError('required')"
                           class="invalid-feedback">
                        <p>Field of Study is required</p>
                      </div>


                    </div>
                    <div class="col-sm-6 styled-select">
                      <label for="gender">Highest Level of Education</label>
                      <select [ngClass]="{'is-invalid': (applicationForm.get('step2').get('educationLevel').touched
    && applicationForm.get('step2').get('educationLevel').errors)}"
                              class="form-control" formControlName="educationLevel" id="educationLevel">
                        <option value="" selected>Select level </option>
                        <option *ngFor="let level of educationLevels" [value]="level">{{ level }}</option>
                      </select>
                      <div *ngIf="applicationForm.get('step2').get('educationLevel').hasError('required')"
                           class="invalid-feedback">
                        Education Level is required
                      </div>
                    </div>

                  </div>
                </div>
                <div *ngIf="currentStep === 3" [hidden]="currentStep !== 3" class="" formGroupName="step3">
                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <label for="totalExperience">Total Experience in year</label>
                      <input class="form-control" formControlName="totalExperience"
                             id="totalExperience" placeholder="Enter years of Experience" type="number">
                      <div *ngIf="applicationForm.get('step3').get('totalExperience').hasError('required')"
                           class="invalid-feedback">
                        Total Experience is required
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <label for="skills">Skills relevant to the vacancy</label>
                      <textarea class="form-control" formControlName="skills" id="skills" placeholder="Enter skills"
                                type="text"> </textarea>
                      <div *ngIf="applicationForm.get('step3').get('skills').hasError('required')"
                           class="invalid-feedback">
                        Skills is required
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="currentStep === 4" [hidden]="currentStep !== 4" class="" formGroupName="step4">
                  <div formArrayName="questions">
                    <div *ngFor="let questionGroup of questionArray.controls; let i = index" [formGroupName]="i">
                      <label>{{getQuestionByUuid(questionGroup.get('questionUuid').value)?.questionNumber}}
                        . {{ getQuestionByUuid(questionGroup.get('questionUuid').value)?.question }}</label>
                      <div
                        *ngIf="getQuestionByUuid(questionGroup.get('questionUuid').value)?.questionType === 'SHORT_ANSWER'">
                        <textarea [formControlName]="'answer'" class="form-control mb-2"
                                  placeholder="Write your answer here"
                                  type="text"> </textarea>
                      </div>
                      <div
                        *ngIf="getQuestionByUuid(questionGroup.get('questionUuid').value)?.questionType === 'MULTIPLE_CHOICE_SINGLE_ANSWER'">
                        <div formArrayName="choiceUuids">
                          <div *ngFor="let choiceGroup of questionGroup.get('choiceUuids')?.controls; let j = index">
                            <label class="container_radio version_2">
                              <input (change)="choiceGroup.get('selected').setValue(true)"
                                     (click)="clearRadioGroup(questionGroup.get('choiceUuids') , j)"
                                     [checked]="choiceGroup.get('selected').value"
                                     [value]="choiceGroup.get('uuid').value"
                                     name="{{i}}" type="radio">
                              <span class="checkmark"></span>
                              {{ getChoiceByUuid(getQuestionByUuid(questionGroup.get('questionUuid').value)?.choices, choiceGroup.get('uuid').value)?.name }}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="getQuestionByUuid(questionGroup.get('questionUuid').value)?.questionType === 'MULTIPLE_CHOICE_MULTIPLE_ANSWER'">
                        <div formArrayName="choiceUuids">
                          <div *ngFor="let choiceGroup of questionGroup.get('choiceUuids')?.controls; let j = index">
                            <label class="container_radio version_2">
                              <input (change)="choiceGroup.get('selected').setValue(true)"
                                     [checked]="choiceGroup.get('selected').value"
                                     [value]="choiceGroup.get('uuid').value"
                                     type="checkbox">
                              <span class="checkmark"></span>
                              {{ getChoiceByUuid(getQuestionByUuid(questionGroup.get('questionUuid').value)?.choices, choiceGroup.get('uuid').value)?.name }}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="btn-group mt-3">
                  <button (click)="previousStep()" [disabled]="currentStep === 1"
                          class="btn btn-sm btn-secondary"
                          type="button">Back
                  </button>
                  <button (click)="nextStep()" [hidden]="currentStep == steps.length"
                          class="btn btn-sm btn-primary"
                          type="button">
                    Next
                  </button>
                  <button [hidden]="currentStep !== steps.length"
                          class="btn btn-sm btn-success" type="submit">Submit


                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
      <div class="card-body" style="overflow-y: scroll">

        <div *ngIf="finished" class="submit step" id="end">
          <div class="summary">
            <div class="text-center mt-0 mb-0">
              <div id="success-icon">
                <i class="bx bx-check-circle fa-4x text-success"></i>
              </div>
            </div>
            <div class="wrapper">

              <h3 class="text-success">Your application has been submitted!<br></h3>
              <p>We will contact you shortly at the following email address <strong class="text-primary"
                                                                                    id="">{{applicantEmail}}</strong>
              </p>
            </div>

          </div>
        </div>

      </div>
    </div>


  </div>

</div>


