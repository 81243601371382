<form (ngSubmit)="validSubmit()" [formGroup]="userForm" class="needs-validation">

  <div class="row ">

    <div class="mb-3 col-md-6">
      <label>First Name <span class="text-danger"> *</span></label>
      <input [ngClass]="{'is-invalid' :(form.firstName.dirty || submit ) && form.firstName.errors }"
             class="form-control edit-input" formControlName="firstName"
             placeholder="Enter First Name"
             type="text">
      <div *ngIf=" form.firstName.errors && (form.firstName.dirty || submit )" class="invalid-feedback">
        <span *ngIf="form.firstName.errors.required">Please provide a valid name.</span>
      </div>
    </div>


    <div class="mb-3 col-md-6">
      <label>Middle Name <span class="text-danger"> *</span></label>
      <input [ngClass]="{'is-invalid' :(form.middleName.dirty || submit )  && form.middleName.errors
         }" class="form-control edit-input" formControlName="middleName"
             placeholder="Enter Middle Name"
             type="text">
      <div *ngIf="(form.middleName.dirty || submit ) && form.middleName.errors" class="invalid-feedback">
        <span *ngIf="form.middleName.errors.required">Please provide a valid name.</span>
      </div>
    </div>

    <div class="mb-3 col-md-6">
      <label>Last Name <span class="text-danger"> *</span></label>
      <input [ngClass]="{'is-invalid' :(form.lastName.dirty || submit )  && form.lastName.errors
         }" class="form-control edit-input" formControlName="lastName" placeholder="Enter Last Name"
             type="text">
      <div *ngIf="(form.lastName.dirty || submit ) && form.lastName.errors" class="invalid-feedback">
        <span *ngIf="form.lastName.errors.required">Please provide a valid name.</span>
      </div>
    </div>

    <div class="mb-3 col-sm-6">
      <label>Phone Number <span class="text-danger"> *</span> </label>
      <input [dropSpecialCharacters]="false" [ngClass]="{'is-invalid' :(form.phoneNumber.dirty || submit) && form.phoneNumber.errors
      }" [showMaskTyped]="true" class="form-control "
             formControlName="phoneNumber" mask="0000-00-00-00" placeholder="Enter Phone Number" type="text">

      <div *ngIf="(form.phoneNumber.dirty || submit) && form.phoneNumber.errors" class="invalid-feedback">
        <span *ngIf="form.phoneNumber.errors">Please provide a valid Phone Number.</span>
      </div>
    </div>


  </div>


  <div class="row ">

    <div class="mb-3 col-md-6">
      <label>Email <span class="text-danger"> *</span> </label>
      <input [ngClass]="{'is-invalid' :(form.email.dirty || submit )  && form.email.errors}"
             class="form-control edit-input" formControlName="email" placeholder="Enter Email"
             type="email">

      <div *ngIf="form.email.dirty  && form.email.errors" class="invalid-feedback">
        <span *ngIf="form.email.errors">Please provide a valid Email.</span>

      </div>
    </div>

    <div class="mb-3 col-md-6">
      <label>Gender <span class="text-danger"> *</span></label>
      <ng-select [items]="genderList" [ngClass]="{'is-invalid' :(form.gender.dirty || submit ) && form.gender.errors}"
                 bindLabel="name"
                 bindValue="name" class="form-control" class="edit-input"
                 formControlName="gender"
                 placeholder="Select Gender">
      </ng-select>

      <div *ngIf="(form.gender.dirty || submit) && form.gender.errors" class="invalid-feedback">
        <span *ngIf="form.gender.errors.required">Please choose a gender.</span>
      </div>
    </div>


  </div>


  <div class="row ">

    <div class="mb-3 col-md-6">
      <label>Role(s) <span class="text-danger"> *</span></label>
      <ng-select [items]="roleList"
                 [multiple]="true"
                 [ngClass]="{'is-invalid' :(form.roles.dirty || submit ) && form.roles.errors}"
                 bindLabel="name" bindValue="name" formControlName="roles"
                 placeholder="Select User  Role">
      </ng-select>

      <div *ngIf="(form.roles.dirty || submit) && form.roles.errors" class="invalid-feedback">
        <span *ngIf="form.roles.errors.required">Please choose a Role.</span>
      </div>
    </div>
  </div>
  <div class="d-flex flex-wrap gap-2">
    <button [disabled]="saving || !formChanged " class="btn btn-primary" type="submit" type="submit">{{actionBtnName}}
      <span *ngIf="saving" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
    </button>
    <button [disabled]="saving " class="btn btn-secondary waves-effect" type="reset">
      Reset
    </button>
  </div>

</form>
