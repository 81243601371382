import { DatePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { AuthService } from "app/core/services/auth/auth.service";
import { TimesheetService } from "app/core/services/timesheet/timesheet.service";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LoaderService } from "../../../core/services/shared/loader.service";
import { AttendaceSubmitDto, Log, WorkLog } from "../attendance";

@Component({
  selector: "app-monthly-sheet",
  templateUrl: "./monthly-sheet.component.html",
  styleUrls: ["./monthly-sheet.component.scss"],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthlySheetComponent implements OnInit {
  @Input() projects: any[];
  @Input() workLogs$: BehaviorSubject<WorkLog>;
  status: "SAVED" | "SUBMITTED" | "COMPLETED" | "CANCELED" | "REJECTED";
  @Input() month: number;
  @Input() showCheckBox: boolean;
  @Input() week: number;
  @Input() weekHrs: number;
  @Input() totalCheckInTimeMinute: number;
  @Input() year: number;
  @Input() showHeader: boolean = true;
  @Output() onCheckedChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onSuccess: EventEmitter<string> = new EventEmitter();
  @Output() onError: EventEmitter<string> = new EventEmitter();
  @Input() enableForm: boolean = true;
  form: FormGroup;
  isChecked: boolean = false;
  showNewRow: boolean[] = [];
  workLogs: Log[];
  destroy: Subject<void> = new Subject<void>();

  constructor(
    private datePipe: DatePipe,
    private loaderService: LoaderService,
    private timeSheetService: TimesheetService,
    private _authService: AuthService,
    private _changeDetector: ChangeDetectorRef,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    /*this.workLogs$.pipe(takeUntil(this.destroy)).subscribe((data) => {
      console.log(data);
      this.workLogs = data.logs;
      this.status = data.status;
      this.initForm();
    });*/
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["workLogs$"]) {
      this.workLogs$.pipe(takeUntil(this.destroy)).subscribe((data) => {
        this.workLogs = data.logs;
        this.status = data.status;
        this.initForm();
      });
    }
  }

  initForm() {
    this.form = this.fb.group({
      year: this.year,
      month: this.month,
      week: this.week,
      empUuid: this._authService.currentUser().employeeUuid,
      logs: this.fb.array([]),
    });

    // Add logs to the form arrayI
    const logsArray = this.form.get("logs") as FormArray;

    this.workLogs.forEach((workLog) => {
      const logGroup = this.fb.group({
        projectUuid: new FormControl({
          value: workLog.projectUuid,
          disabled: this.status === "SUBMITTED" || this.status === "COMPLETED",
        }),
        totalHrs: new FormControl(workLog.totalHrsMinute),
        days: this.fb.array([]),
      });

      const daysArray = logGroup.get("days") as FormArray;

      workLog.days.forEach((day) => {
        const timeValue = day.onLeave ? "00:00" : day.time;
        const dayGroup = this.fb.group({
          date: day.date,
          time: new FormControl({
            value: timeValue,
            disabled:
              day.onLeave ||
              this.status === "SUBMITTED" ||
              this.status === "COMPLETED",
          }),
          onLeave: day.onLeave,
        });
        daysArray.push(dayGroup);
      });

      logsArray.push(logGroup);
    });
  }
  onCheckboxChange() {
    this.onCheckedChange.emit(this.isChecked);
  }

  onSubmit() {
    this.loaderService.showProgress.next(true);

    const data: AttendaceSubmitDto = {
      year: this.year,
      month: this.month,
      weeks: [this.week],
      empUuid: this._authService.currentUser().employeeUuid,
    };

    this.timeSheetService
      .submittWorkLog(this._authService.currentUser().employeeUuid, data)
      .subscribe({
        next: (resp) => {
          this.loaderService.showProgress.next(false);
          this.onSuccess.emit("Work Log submitted successfully!");
        },
        error: (err) => {
          console.log(err);
          this.loaderService.showProgress.next(false);
          this.onError.emit(
            "Error submitting work log! please try again later"
          );
        },
      });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  onSave() {
    this.loaderService.showProgress.next(true);
    if (this.form.valid) {
      this.form.disable();
      this.timeSheetService
        .saveWorkLog(
          this._authService.currentUser().employeeUuid,
          this.form.value
        )
        .subscribe({
          next: (resp) => {
            this.onSuccess.emit("Work Log added successfully!");
            this.loaderService.showProgress.next(false);
          },
          error: (err) => {
            this.onError.emit("Error saving work log! please try again later");
            this.loaderService.showProgress.next(false);
            this.form.enable();
          },
        });
    }
  }

  formatDate(date: string): string {
    return this.datePipe.transform(date, "MMM-dd-yyyy");
  }

  get logs(): FormArray {
    return this.form.get("logs") as FormArray;
  }

  addLog() {
    const logGroup = this.fb.group({
      projectUuid: new FormControl(null),
      totalHrs: new FormControl(0),
      days: this.fb.array([]),
    });

    const daysArray = logGroup.get("days") as FormArray;

    this.workLogs[0].days.forEach((day) => {
      const dayGroup = this.fb.group({
        date: day.date,
        time: new FormControl({ value: "00:00", disabled: day.onLeave }),
        onLeave: day.onLeave,
      });
      daysArray.push(dayGroup);
    });

    this.logs.push(logGroup);
  }

  removeLog(index: number) {
    this.logs.removeAt(index);
  }
}
