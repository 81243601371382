import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  NgbAccordionModule,
  NgbAlertModule,
  NgbDatepickerModule,
  NgbPaginationModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { SharedModule } from "app/shared/shared.module";
import { UIModule } from "app/shared/ui/ui.module";
import { NgxMaskModule } from "ngx-mask";
import { LeaveRoutingModule } from "../leave/leave-routing.module";
import { AttendanceLogComponent } from "./attendance-log/attendance-log.component";
import { AttendanceRequestComponent } from "./attendance-request/attendance-request.component";
import { AttendanceRoutingModule } from "./attendance-routing.module";
import { MonthlySheetComponent } from "./monthly-sheet/monthly-sheet.component";

@NgModule({
  declarations: [
    AttendanceLogComponent,
    AttendanceRequestComponent,
    MonthlySheetComponent,
  ],
  imports: [
    CommonModule,
    AttendanceRoutingModule,
    NgxMaskModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbAccordionModule,
    NgbAlertModule,
    NgbDatepickerModule,
    LeaveRoutingModule,
    NgbPaginationModule,
    NgbTooltipModule,
    SharedModule,
    UIModule,
  ],
  exports: [MonthlySheetComponent],
})
export class AttendanceModule {}
