import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbAlert, NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from 'app/core/services/auth/auth.service';
import { Leave, LeaveRequest, LeaveStatusEnum, LeaveType } from 'app/core/services/leave/leave';
import { LeaveService } from 'app/core/services/leave/leave.service';
import { LoaderService } from 'app/core/services/shared/loader.service';
import { RolesEnum } from 'app/shared/models/Roles';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime } from "rxjs/operators";

@Component({
  selector: 'app-my-leave',
  templateUrl: './leave-requests.component.html',
  styleUrls: ['./leave-requests.component.scss']
})

export class LeaveRequestsComponent implements OnInit {

  requestToBeUpdated: Leave | null;
  public leaveRequests$ = new BehaviorSubject<Leave[]>([]);
  public modalRef: any;
  requestDecline: boolean = false;
  declineReason: string;
  isUpdate: boolean = false;
  _message = new Subject<string>();
  message = "";
  submit: boolean = false;
  alertType = "";
  saving = false;
  modalTitle: string;
  @ViewChild("confirmationModal") confirmationModal: ElementRef;
  @ViewChild("selfClosingAlert", { static: false }) selfClosingAlert: NgbAlert;
  requestForm: FormGroup;
  evaluationStatusList: string[] = [];
  constructor(private _fb: FormBuilder,
    private loaderService: LoaderService,
    private authService: AuthService,
    private leaveService: LeaveService,
    private activatedRoute: ActivatedRoute,
    private _modalService: NgbModal) { }
  leaveTypes: any[]; // Array to store leave types
  selectedLeaveType: string; // To store the selected leave type UUID
  fromDate: NgbDateStruct; // To store the selected from date
  toDate: NgbDateStruct; // To store the selected to date
  reason: string; // To store the reason for the leave request

  ngOnInit(): void {
    //config alert
    this._message.pipe(debounceTime(3000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });

    //fetch data from resolver
    this.activatedRoute.data.subscribe({
      next: (data: { apiData: [LeaveType[], Leave[]] }) => {
        this.leaveTypes = data.apiData[0];
        this.leaveRequests$.next(data.apiData[1])
      }, error: (err) => {
        console.log(err);
      }

    })
  }

  refreshData() {
    const requestsRole = "manager";
    this.leaveService.getLeaveRequests(requestsRole, this.authService.currentUser().employeeUuid)
      .subscribe({
        next: (resp => {
          this.leaveRequests$.next(resp);
        }),
        error: (err => {
          console.log(err);

        })
      })
  }

  get form() {
    return this.requestForm.controls;
  }

  // Function to handle form submission
  submitForm() {
    this.submit = true;
  }


  onApprove(request: Leave) {
    this.requestToBeUpdated = request
    this.requestDecline = false;
    this.modalTitle = "Approve Leave Request?"

    this.modalRef = this._modalService.open(this.confirmationModal, {
      scrollable: false,
      size: "md",
      centered: false,
    });
  }

  onDecline(request: Leave) {
    this.requestToBeUpdated = request
    this.requestDecline = true;
    this.modalTitle = "Decline Leave Request?"

    this.modalRef = this._modalService.open(this.confirmationModal, {
      scrollable: false,
      size: "md",
      centered: false,
    });
  }

  submitConfirmation() {
    const userType = "manager";
    this.loaderService.showProgress.next(true);

    const commonData = {
      remark: this.requestDecline ? LeaveStatusEnum.REJECTED : LeaveStatusEnum.APPROVED_MANAGER,
      approverUuid: this.authService.currentUser()?.employeeUuid
    };

    let data: { remark: string; approverUuid: string; } & { status: LeaveStatusEnum; };

    if (!this.requestDecline) {
      data = Object.assign({}, commonData, {
        status: LeaveStatusEnum.APPROVED_MANAGER,
        remark: ''
      });
    } else {
      data = Object.assign({}, commonData, {
        status: LeaveStatusEnum.REJECTED,
        remark: this.declineReason
      });
    }

    this.leaveService.approveLeaveRequest(userType, this.requestToBeUpdated.uuid, data)
      .subscribe({
        next: (resp) => {
          console.log(resp);
          this.loaderService.showProgress.next(false);
          this._modalService.dismissAll()
          //success notification
          this.refreshData();
          this.alertType = "success";
          this._message.next(this.message);
          this.message = "Leave request approved successfully!";
        },
        error: (err => {
          console.log(err);
          this.loaderService.showProgress.next(false);
          this._modalService.dismissAll()
          //error notification
          this.alertType = "danger";
          this._message.next(this.message);
          this.message = "Error sending  your request! please try again later";

        })
      });
  }
}
