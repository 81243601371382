import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbAlert, NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateCustomParserFormatter } from 'app/core/helpers/ngb-date-formatter';
import { AuthService } from 'app/core/services/auth/auth.service';
import { LeaveStatistics } from 'app/core/services/leave/LeaveStat';
import { Leave, LeaveType } from 'app/core/services/leave/leave';
import { LeaveService } from 'app/core/services/leave/leave.service';
import { LoaderService } from 'app/core/services/shared/loader.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime } from "rxjs/operators";


@Component({
  selector: 'app-my-leave',
  templateUrl: './my-leave.component.html',
  styleUrls: ['./my-leave.component.scss']
})

export class MyLeaveComponent implements OnInit {

  leaveToBeUpdated: Leave | null;
  statistics: LeaveStatistics;

  public modalRef: any;
  isUpdate: boolean = false;
  _message = new Subject<string>();
  message = "";
  submit: boolean = false;
  alertType = "";
  saving = false;
  modalTitle: string;
  @ViewChild("leaveRequestModal") leaveRequestModal: ElementRef;
  @ViewChild("selfClosingAlert", { static: false }) selfClosingAlert: NgbAlert;
  requestForm: FormGroup;
  public leaveRequests$ = new BehaviorSubject<Leave[]>([]);
  constructor(private _fb: FormBuilder,
    private _modalService: NgbModal,
    private authService: AuthService,
    private _loaderService: LoaderService,
    private dateFormatter: NgbDateCustomParserFormatter,
    private leaveService: LeaveService,
    private activatedRoute: ActivatedRoute) { }
  leaveTypes: LeaveType[]; // Array to store leave types
  selectedLeaveType: string; // To store the selected leave type UUID
  fromDate: NgbDateStruct; // To store the selected from date
  toDate: NgbDateStruct; // To store the selected to date
  reason: string; // To store the reason for the leave request

  ngOnInit(): void {

    //fetch data from resolver
    this.activatedRoute.data.subscribe({
      next: (data: { apiData: [LeaveType[], Leave[], LeaveStatistics] }) => {
        this.leaveTypes = data.apiData[0];
        this.leaveRequests$.next(data.apiData[1]);
        this.statistics = data.apiData[2];
      }, error: (err) => {
        console.log(err);
      }

    })

    this.requestForm = this._fb.group({


      leaveTypeUuid: new FormControl(null,
        {
          validators: [Validators.required],


        }),
      remark: new FormControl(null,
        {
          validators: [Validators.required],
          updateOn: 'change'

        }),
      toDate: new FormControl(null, {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      fromDate: new FormControl(null, {
        validators: [Validators.required],
        updateOn: 'change'
      })
    });

    //config alert
    this._message.pipe(debounceTime(3000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  get form() {
    return this.requestForm.controls;
  }
  scrollToTop() {

  }

  // Function to handle form submission
  submitForm() {
    this.submit = true;
    let requestData = this.requestForm.value
    const fromDate: any = requestData.fromDate;
    const toDate: any = requestData.toDate;
    requestData.fromDate = this.dateFormatter.format(fromDate);
    requestData['employeeUuid'] = this.authService.currentUser().employeeUuid;
    requestData.toDate = this.dateFormatter.format(toDate);

    if (this.requestForm.valid && !this.isUpdate) {
      this.requestForm.disable();
      this.addRequest(requestData);
    } else if (this.requestForm.valid && this.isUpdate) {
      this.requestForm.disable();
      this.updateRequest(requestData)
    }
    else {

      return;
    }
  }

  addRequest(data: any): void {
    this._loaderService.showProgress.next(true);
    this.leaveService.requestLeave(data).subscribe(
      () => {

        //close Modal
        this._modalService.dismissAll(this.leaveRequestModal);
        this._loaderService.showProgress.next(false);

        //reload Data
        this.refreshData();
        this.scrollToTop();

        //success notification
        this.alertType = "success";
        this._message.next(this.message);
        this.message = "Leave request sent successfully!";
        this.saving = false;

      },
      (error) => {
        console.log(error);

        this.saving = false;
        this._modalService.dismissAll(this.leaveRequestModal);
        this._loaderService.showProgress.next(false);

        //error notification
        this.alertType = "danger";
        this._message.next(this.message);
        this.message = "Error sending  Leave request! please try again later";
      }
    );
  }


  updateRequest(data: any): void {
    this._loaderService.showProgress.next(true);
    this.leaveService.updateLeaveRequest(data, this.leaveToBeUpdated.uuid).subscribe(
      () => {

        //close Modal
        this._modalService.dismissAll(this.leaveRequestModal);
        this._loaderService.showProgress.next(false);

        this.scrollToTop();
        //success notification
        this.alertType = "success";
        this._message.next(this.message);
        this.message = "Leave request updated successfully!";
        this.saving = false;
        //reload Data
        this.refreshData();


      },
      (error) => {
        console.log(error);

        this.saving = false;
        this._modalService.dismissAll(this.leaveRequestModal);
        this._loaderService.showProgress.next(false);

        //error notification
        this.alertType = "danger";
        this._message.next(this.message);
        this.message = "Error updating  Leave request! please try again later";
      }
    );
  }


  refreshData() {
    this._loaderService.showProgress.next(true);
    this.leaveService.fetchEmployeeLeaveData(this.authService.currentUser().employeeUuid)
      .subscribe({
        next: ([leaves, leaveStat]) => {
          this.leaveRequests$.next(leaves);
          this.statistics = leaveStat;
          this._loaderService.showProgress.next(false);
        },
        error: (err => {
          // Handle errors
          this._loaderService.showProgress.next(false);
          console.log(err);
        })
      });
  }

  onAddLeaveRequest() {
    this.isUpdate = false;
    this.modalTitle = "Leave Request"
    this.requestForm.reset();
    this.requestForm.enable();
    this.modalRef = this._modalService.open(this.leaveRequestModal, {
      scrollable: false,
      size: "sm",
      centered: false,
    });
  }

  onUpdateLeaveRequest(leave: Leave) {
    this.leaveToBeUpdated = leave;
    this.isUpdate = true;
    this.requestForm.enable();
    this.requestForm.patchValue(leave);
    this.form.fromDate.setValue(this.dateFormatter.parse(leave.fromDate))
    this.form.toDate.setValue(this.dateFormatter.parse(leave.toDate))
    this.modalTitle = "Update Leave Request"

    this.modalRef = this._modalService.open(this.leaveRequestModal, {
      scrollable: false,
      size: "sm",
      centered: false,
    });
  }
}
