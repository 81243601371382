<div class="card shadow-lg mt-4">
  <div class="d-flex justify-content-between card-header">
    <div>
      <div class="d-flex align-items-center">
        <div *ngIf="showCheckBox" class="form-check me-3">
          <input
            class="form-check-input form-check"
            type="checkbox"
            [(ngModel)]="isChecked"
            (change)="onCheckboxChange()"
          />
        </div>
        <div>
          <strong *ngIf="showHeader">Summary - week {{ week }}</strong>
        </div>
      </div>
      <div>
        <strong class="text-info">Total Hrs: {{ weekHrs }}</strong>
      </div>
      <div>
        <strong class="text-info"
          >Total Checked in Hrs: {{ totalCheckInTimeMinute }}</strong
        >
      </div>
    </div>

    <strong class="text-info">{{ this.status }}</strong>
  </div>

  <div class="card-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="responsive-table">
        <table class="table table-sm">
          <thead>
            <tr>
              <th style="width: 15%">Project</th>
              <th class="text-center" *ngFor="let day of workLogs[0].days">
                <div>
                  {{ formatDate(day.date) }}
                </div>
                <small
                  class="text-secondary text-info"
                  *ngIf="day.description"
                  >{{ day.description }}</small
                >
              </th>
              <th>Total</th>
              <th *ngIf="showHeader"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let log of logs.controls; let i = index"
              [formGroup]="log"
            >
              <td>
                <select
                  formControlName="projectUuid"
                  class="form-control form-select"
                >
                  <option selected value="null">Select</option>
                  <option
                    *ngFor="let project of projects"
                    [value]="project.uuid"
                  >
                    {{ project.name }}
                  </option>
                </select>
              </td>
              <td
                *ngFor="let day of log.get('days').controls; let j = index"
                [formGroup]="day"
              >
                <input
                  [dropSpecialCharacters]="false"
                  [showMaskTyped]="true"
                  class="form-control"
                  mask="00:00"
                  formControlName="time"
                  type="text"
                />
              </td>
              <td class="text-start">
                <strong>{{ log.get("totalHrs").value || "" }} Hrs</strong>
              </td>
              <td *ngIf="showHeader">
                <i
                  *ngIf="
                    i == 0 &&
                    this.status != 'SUBMITTED' &&
                    this.status != 'COMPLETED'
                  "
                  class="mdi mdi-plus p-6 mr-2"
                  (click)="addLog()"
                ></i>
                <i
                  *ngIf="
                    i >= 1 &&
                    this.status != 'SUBMITTED' &&
                    this.status != 'COMPLETED'
                  "
                  class="mdi mdi-delete text-danger p-6"
                  (click)="removeLog(i)"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  </div>
  <div class="card-footer">
    <div class="btn-group col-sm-3">
      <button
        *ngIf="this.status != 'SUBMITTED' && this.status != 'COMPLETED'"
        (click)="onSave()"
        class="btn btn-primary"
      >
        Save
      </button>

      <button
        class="btn btn-success"
        *ngIf="this.status != 'SUBMITTED' && this.status != 'COMPLETED'"
        (click)="onSubmit()"
      >
        Submit for Review
      </button>
    </div>
  </div>
</div>
