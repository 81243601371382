import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgbAlertModule, NgbDropdownModule, NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {SimplebarAngularModule} from 'simplebar-angular';
import {ClickOutsideModule} from 'ng-click-outside';

import {UIModule} from '../shared/ui/ui.module';
import {LayoutComponent} from './layout.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {TopBarComponent} from './topbar/topbar.component';
import {FooterComponent} from './footer/footer.component';
import {VerticalComponent} from './vertical/vertical.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    // tslint:disable-next-line: max-line-length
    declarations: [LayoutComponent, SidebarComponent,
        TopBarComponent, FooterComponent,
        VerticalComponent],
    imports: [
        CommonModule,
        NgbAlertModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgbNavModule,
        NgbDropdownModule,
        ClickOutsideModule,
        UIModule,
        SimplebarAngularModule
    ],
    exports: [
        TopBarComponent
    ],
    providers: []
})
export class LayoutsModule {
}
