import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbCollapseModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbNavModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { NgxChartistModule } from 'ngx-chartist';
import { NgxEchartsModule } from 'ngx-echarts';
import { SimplebarAngularModule } from 'simplebar-angular';
import { AccountModule } from './account/account.module';
import { UsersModule } from './users/users.module'
import { UIModule } from 'app/shared/ui/ui.module';
import { LeaveModule } from './leave/leave.module';
import { AttendanceModule } from './attendance/attendance.module';

@NgModule({

  imports: [
    NgxEchartsModule,
    NgxChartistModule,
    CommonModule,
    UIModule,
    FormsModule,
    NgbDropdownModule,
    NgbModalModule,
    AttendanceModule,
    AccountModule,
    ReactiveFormsModule,
    UsersModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbCollapseModule,
    SimplebarAngularModule,
    LeaveModule
  ],
})
export class PagesModule {
}
