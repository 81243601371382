<div class="row mb-md-2 mt-3 radius-10">
  <div class="d-flex justify-content-between">
    <div *ngIf="enableAdd" class="mb-3">
      <button (click)="onAdd()" class="btn btn-outline-primary">Add</button>
    </div>

    <div *ngIf="enableStatusFilter" class="mb-3 col-md-4 col-sm-4">
      <label><i class="bx bx-filter-alt text-primary"></i> Status</label>
      <ng-select
        [(ngModel)]="statusSearchTerm"
        [clearable]="true"
        [items]="statusList"
        bindLabel="name"
        bindValue="value"
        class="edit-input"
        placeholder="All"
      >
      </ng-select>
    </div>

    <!-- Search -->
    <div *ngIf="enableSearch" class="col-sm-4">
      <label>Search</label>
      <input
        [(ngModel)]="searchTerm"
        aria-controls="tickets-table"
        class="form-control form-control"
        name="searchTerm"
        placeholder="Search..."
        type="text"
      />
    </div>
  </div>

  <!-- End search -->
</div>
<!-- Table -->
<div class="table-responsive">
  <table class="table table-sm table-hover table-striped-columns">
    <thead>
      <tr>
        <th
          *ngFor="let header of displayHeaders; let isFirst = first"
          [ngStyle]="{ width: isFirst ? '20%' : '' }"
          style="width: 20%"
        >
          {{ header.name }}
        </th>
        <th *ngIf="enableAction" style="width: 20%">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr
        (click)="onRowClick(data)"
        *ngFor="let data of data$ | async; let i = index"
        style="cursor: pointer"
      >
        <td
          *ngFor="let element of elements"
          [ngClass]="{ 'text-truncate': element == 'email' }"
          class="align-middle"
        >
          <div
            *ngIf="
              element == 'contractStatusDescription' || element == 'status'
            "
            [ngClass]="{
              'bg-valid':
                data['contractStatus'] == 0 || data['status'] == 'ACTIVE',
              'bg-level1': data['contractStatus'] == 1,
              'bg-level2': data['contractStatus'] == 2,
              'bg-level3': data['contractStatus'] == 3,
              'bg-level4': data['contractStatus'] == 4
            }"
            class="p-2"
            style="width: 100%"
          >
            <span *ngIf="data['contractStatus'] == 0"> Valid </span>
            <span *ngIf="data['contractStatus'] !== 0">
              {{ data[element] }}
            </span>
          </div>

          <div
            *ngIf="
              element !== 'contractStatusDescription' && element !== 'status'
            "
          >
            {{ data[element] }}
          </div>
        </td>

        <td
          *ngIf="enableAction"
          (click)="$event.stopPropagation()"
          class="align-middle"
        >
          <div class="btn-group">
            <button
              (click)="onEdit(data['uuid'])"
              *ngIf="enableEdit"
              class="btn btn-sm btn-outline-primary"
            >
              Edit
            </button>

            <button
              (click)="onCustomAction(data['uuid'])"
              *ngIf="enableCustomAction"
              class="btn btn-sm btn-outline-primary"
            >
              {{ customActionName }}
            </button>

            <button
              (click)="onCustomAction2(data['uuid'])"
              *ngIf="enableCustomAction2"
              class="btn btn-sm btn-outline-primary"
            >
              {{ customAction2Name }}
            </button>

            <button
              (click)="onDelete(data['uuid'])"
              *ngIf="enableDelete"
              class="btn btn-sm btn-outline-danger"
            >
              Delete
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!-- End table -->
<div
  *ngIf="paginated"
  class="row justify-content-md-between align-items-md-center mt-2"
>
  <div class="col-sm-12 col-md-5">
    <div
      aria-live="polite"
      class="dataTables_info mb-2"
      id="tickets-table_info"
      role="status"
    >
      Showing {{ startIndex }} to {{ endIndex }} of {{ totalRecords }}
      entries
    </div>
  </div>
  <!-- Pagination -->
  <div class="col-sm-12 col-md-5">
    <div class="text-md-right float-md-end pagination-rounded">
      <ngb-pagination
        [(page)]="page"
        [collectionSize]="total$ | async"
        [pageSize]="pageSize"
      >
      </ngb-pagination>
    </div>
  </div>
  <!-- End Pagination -->
</div>
