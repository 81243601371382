import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators,} from "@angular/forms";
import {AuthService} from 'app/core/services/auth/auth.service';
import {User} from 'app/core/services/user/user';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Subject} from "rxjs";
import {UserService} from "../../core/services/user/user.service";
import {LoaderService} from "../../core/services/shared/loader.service";
import Validation from "../../core/helpers/Util";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopBarComponent implements OnInit {

  element: any;
  currentUser: User;
  username: string = '';
  savingPassword: boolean = false;
  passwordChangeForm: FormGroup;
  public modalRef: any;
  message = "";
  passwordFormSubmit: boolean;
  _message = new Subject<string>();
  alertType = "";
  visiblePassword = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private _userService: UserService,
    private _loaderService: LoaderService,
    private _modalService: NgbModal,
    private _fb: FormBuilder) {
  }

  openMobileMenu: boolean;
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  @ViewChild("profileModal") profileModal: ElementRef;

  ngOnInit() {
    this.passwordFormSubmit = false;
    this.openMobileMenu = false;
    this.element = document.documentElement;
    this.currentUser = this.authService.currentUser();
    this.username = this.currentUser.fullName;
    this.passwordChangeForm = this._fb.group({
      oldPassword: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', [Validators.required, Validation.match('password', 'confirmPassword')])
    });


  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  passwordChangeSubmit() {
    this.passwordFormSubmit = true;

    if (this.passwordChangeForm.valid) {
      this._loaderService.showProgress.next(true);
      this.savingPassword = true;
      this._userService.changeMyPassword(this.passwordChangeForm.value)
        .subscribe({
          next: () => {
            this.savingPassword = false;
            this.alertType = "success";
            this.message =
              "Password Changed Successfully!";
            this._message.next(this.message);
            this._loaderService.showProgress.next(false);
          },
          error: (err) => {
            this.savingPassword = false;
            this.alertType = "danger";
            this._message.next(this.message);
            this.message = err.error.apierror.message;
            this._loaderService.showProgress.next(false);
          }

        })

    }
  }

  get passwordForm() {
    return this.passwordChangeForm.controls;
  }

  onPasswordChange() {
    this.modalRef = this._modalService.open(this.profileModal, {
      scrollable: false,
      size: "lg",
      centered: false,
    });
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.signOut();
    this.router.navigate(['/account/login']);
  }

  /** toggle password visibility */
  togglePasswordField() {
    this.visiblePassword = !this.visiblePassword;
  }


  onProfile() {

    this.router.navigateByUrl((`/employee/profile?uuid=${this.authService.currentUser().employeeUuid}`));
  }
}
