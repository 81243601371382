import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Leave } from 'app/core/services/leave/leave';
import { State } from 'app/shared/utils';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { debounceTime, delay, switchMap, tap } from "rxjs/operators";

interface searchResult {
  data: Leave[];
  total: number;
}

function matches(leave: Leave, term: string) {
  return (
    leave.leaveType.toLowerCase().includes(term) ||
    leave.employee.firstName.toLowerCase().includes(term)
  );
}
@Component({
  selector: 'app-leave-request-table',
  templateUrl: './leave-request-table.component.html',
  styleUrls: ['./leave-request-table.component.scss']
})
export class LeaveRequestTableComponent implements OnInit {

  @Input() leaves: Leave[] = [];
  @Input() employeeType: 'employee' | 'manager' | 'hr'
  @Output() onAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onApprove: EventEmitter<Leave> = new EventEmitter<Leave>();
  @Output() onDecline: EventEmitter<Leave> = new EventEmitter<Leave>();
  @Output() onUpdate: EventEmitter<Leave> = new EventEmitter<Leave>();
  public leaves$ = new BehaviorSubject<Leave[]>([]);
  private _State: State;
  private _search$ = new Subject<void>();
  private _totalLeaves$ = new BehaviorSubject<number>(0);
  private _loading$ = new BehaviorSubject<boolean>(true);

  constructor() { }

  ngOnInit(): void {

    this._State = {
      page: 1,
      pageSize: 5,
      searchTerm: "",
      statusSearchTerm: '',
      sortColumn: "",
      startIndex: 0,
      endIndex: 5,
      totalRecords: 0,
    };

    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(100),
        switchMap(() => this._search()),
        delay(100),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this.leaves$.next(result.data);
        this._totalLeaves$.next(result.total);
      });

    this._search$.next();

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.leaves && changes.leaves.currentValue) {
      // Handle the updated leaves data
      this._search$.next()
    }
  }

  addLeaveRequest() {
    this.onAdd.emit();
  }
  updateLeaveRequest(leave: Leave) {
    this.onUpdate.emit(leave);
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }


  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
    //this._search$.next();
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._State, patch);
    this._search$.next();
  }

  get startIndex() {
    return this._State.startIndex;
  }

  get total$() {
    return this._totalLeaves$.asObservable();
  }

  get endIndex() {
    return this._State.endIndex;
  }

  get totalRecords() {
    return this._State.totalRecords;
  }

  get page() {
    return this._State.page;
  }

  get pageSize() {
    return this._State.pageSize;
  }

  get searchTerm() {
    return this._State.searchTerm;
  }

  set page(page: number) {
    this._set({ page });
  }

  _search(): Observable<searchResult> {

    const { page, searchTerm } = this._State;
    // 1. sort
    let data = this.leaves;
    data = data.filter((leave) => matches(leave, searchTerm));
    const total = data.length;
    // 3. paginate
    this._State.totalRecords = data.length;
    this._State.startIndex = (page - 1) * this.pageSize + 1;
    this._State.endIndex = (page - 1) * this.pageSize + this.pageSize;

    if (this.endIndex > this.totalRecords) {
      this._State.endIndex = this.totalRecords;
    }
    data = data.slice(this._State.startIndex - 1, this._State.endIndex);

    return of({ data, total });

  }

  approve(leave: Leave) {
    this.onApprove.emit(leave);
  }

  decline(leave: Leave) {
    this.onDecline.emit(leave);
  }

}
