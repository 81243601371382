<div id="layout-wrapper">

  <app-topbar
    (mobileMenuButtonClicked)="onToggleMobileMenu()"
    (settingsButtonClicked)="onSettingsButtonClicked()">
  </app-topbar>

  <app-sidebar [isCondensed]="isCondensed"></app-sidebar>

  <div class="main-content">

    <div class="page-content">

    <!--<ngb-alert #selfClosingAlert (closed)="message = ''" *ngIf="message" [dismissible]="false" [type]="alertType">
      {{ message }}
    </ngb-alert> -->

      <!-- content -->
      <router-outlet></router-outlet>
    </div>

    <!-- footer -->
    <app-footer></app-footer>
  </div>
</div>
