import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {RolesEnum} from "app/shared/models/Roles";
import {User} from "../user/user";

@Injectable({
  providedIn: "root",
})
export class RoleBasedRoutingService {
  constructor(private router: Router) {
  }

  routeToLanding(user: User) {

    switch (true) {

      case user.roles.includes(RolesEnum.SUPER_ADMIN) || user.roles.includes(RolesEnum.HR_MANAGER):
        this.router.navigate(["/dashboard"]);
        break;
      case user.roles.includes(RolesEnum.EMPLOYEE):
        this.router.navigate(["/dashboard/employee"]);
        break;
      default:
        this.router.navigate(['/unauthorized']);
        break;
    }
  }
}
