import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {first} from "rxjs/operators";
import {RoleBasedRoutingService} from "app/core/services/auth/role-based-routing.service";
import {UserLogin} from "app/core/services/auth/Login-model";
import {AuthService} from "app/core/services/auth/auth.service";
import {Router} from "@angular/router";
import {LoaderService} from "app/core/services/shared/loader.service";
import {HttpClient} from '@angular/common/http';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})

export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  loading = false;
  error = "";
  visiblePassword = false;
  year: number = new Date().getFullYear();
  loginData: UserLogin;

  constructor(
    private formBuilder: FormBuilder,
    private _authService: AuthService,
    private router: Router,
    private _http: HttpClient,
    private _loaderService: LoaderService,
    private roleBasedRouting: RoleBasedRoutingService
  ) {
  }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      username: ["", [Validators.required]],
      password: ["", [Validators.required]],

    });

  }

  get f() {
    return this.loginForm.controls;
  }

  /**
   * Form submit
   */
  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      //invalid credentials
      return;
    } else {

      this._loaderService.showProgress.next(true);
      this.loading = true;
      this.loginForm.disable();

      const cred = {
        username: this.f.username.value,
        password: this.f.password.value,
      }

      this._authService
        .login(cred)
        .pipe(first())
        .subscribe(
          (user) => {
            this._loaderService.showProgress.next(false);
            this.submitted = false;
            this.loginForm.enable()
          this.roleBasedRouting.routeToLanding(user);
          },
          (error) => {
            this.submitted = false;
            this.loading = false
            this.loginForm.enable();
            this.error = error ? error : "";
            this._loaderService.showProgress.next(false);
          }
        );


    }
  }

  /** toggle password visibility */
  togglePasswordField() {
    this.visiblePassword = !this.visiblePassword;
  }
}
